import { AxiosRequestConfig } from 'axios';

import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestBuildsPayloadType } from '@/utils/type/IApiBuilds';

const fetchBuilds = async (payload: RequestBuildsPayloadType) => {
  const { method } = payload;
  let config: AxiosRequestConfig = { method };
  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.builds(payload?.endpointParams?.buildPK);
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, 'res'))();
};

export const buildsServices = { fetchBuilds };
