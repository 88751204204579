import { combineReducers } from 'redux';

import { appletReducer } from "@/store/applet";
import { buildAuthReducer } from '@/store/buildAuth';
import { buildsReducer } from '@/store/builds';
import { emsBuildReducer } from '@/store/emsBuild';
import { emsBuildHistoryReducer } from '@/store/emsBuildHistory';
import { fsaReducer } from '@/store/fsa';
import { householdReducer } from '@/store/household';
import { leaseReducer } from '@/store/lease';
import { loginInitialState, loginReducer } from '@/store/login';
import { repairReducer } from '@/store/repair';
import { repairFileReducer } from '@/store/repairFile';
import { repairTypeReducer } from '@/store/repairType';
import { reportReducer } from '@/store/report';
import { reportDashboardReducer } from '@/store/reportDashboard';
import { reportScheduleReducer } from '@/store/reportSchedule';
import { sensorTopoLocationReducer } from '@/store/sensorTopoLocation';
import { tenantReducer } from '@/store/tenant';
import { topoReducer } from "@/store/topo";
import { unitReducer } from '@/store/unit';
import { updateSelfOrChangePasswordReducer } from '@/store/updateSelfOrChangePassword';
import { usersReducer } from '@/store/users';
import { warningReducer } from '@/store/warning';

import { connectionReducer } from './connection';
import { connectionTypeReducer } from './connectionType';
import { sensorReducer } from './sensor';
import { topoBuildingReducer } from './topoBuilding';


const reducers = combineReducers({
  login: loginReducer,
  buildAuth: buildAuthReducer,
  builds: buildsReducer,
  unit: unitReducer,
  changePassword: updateSelfOrChangePasswordReducer,
  users: usersReducer,
  household: householdReducer,
  lease: leaseReducer,
  tenant: tenantReducer,
  repair: repairReducer,
  repairFile: repairFileReducer,
  repairType: repairTypeReducer,
  report: reportReducer,
  reportDashboard: reportDashboardReducer,
  reportSchedule: reportScheduleReducer,
  emsBuild: emsBuildReducer,
  emsBuildHistory: emsBuildHistoryReducer,
  fsa: fsaReducer,
  topo: topoReducer,
  warning: warningReducer,
  applet: appletReducer,
  connection: connectionReducer,
  connectionType: connectionTypeReducer,
  topoBuilding: topoBuildingReducer,
  sensor: sensorReducer,
  sensorTopoLocation: sensorTopoLocationReducer,
});

export const rootReducer = (state: any, action: any) => {
  let finalState: any;
  switch (action.type) {
    case 'RESET':
      finalState = undefined;
      break;
    case 'RESET_EXCEPT_LOGIN':
      finalState = { login: state.login };
      break;
    case 'RESET_EXCEPT_IS_LOGIN_AGAIN':
      finalState = {
        login: { ...loginInitialState, isLoginAgain: state.login.isLoginAgain },
      };
      break;
    default:
      finalState = state;
  }

  return reducers(finalState, action);
};
