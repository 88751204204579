import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import { connectionActions } from "@/store/connection";
import { connectionServices } from "@/store/connection/service";
import { getAxiosErrMsg } from "@/utils/function";
import { IConnection, RequestConnectionPayloadType } from "@/utils/type/IApiConnection";

function* handleRequestConnection({
  payload,
}: PayloadAction<RequestConnectionPayloadType>) {
  try {
    const data: IConnection[] = yield call(
      connectionServices.fetchConnection,
      payload,
    );
    const { isFullReplacement, method } = payload;
    yield put(
      connectionActions.success({
        isFullReplacement,
        successMethod: method,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(connectionActions.failure(errMsg));
  }
}
export function* connectionSaga() {
  yield takeLatest(connectionActions.request.type, handleRequestConnection);
}