export const IS_DEV: boolean = process.env.NODE_ENV === 'development';

export const DUMMY_GMAIL_ACCOUNT_FOR_FAKER: string =
  process.env.REACT_APP_DUMMY_GMAIL_ACCOUNT_FOR_FAKER ?? '';

export const APP_VERSION = process.env.REACT_APP_VERSION
  ? 'v' + process.env.REACT_APP_VERSION
  : '';

export const API_URL = {
  GENERAL: process.env.REACT_APP_GENERAL_API_URL ?? '',
  REPAIR: process.env.REACT_APP_REPAIR_API_URL ?? '',
  EMS: process.env.REACT_APP_EMS_API_URL ?? '',
  FSA: process.env.REACT_APP_FSA_API_URL ?? '',
  BTS: process.env.REACT_APP_BTS_API_URL ?? '',
} as const;

export const EXTERNAL_URL = {
  BIM: process.env.REACT_APP_BIM_URL ?? '',
  DASHBOARD: process.env.REACT_APP_DASHBOARD_URL ?? '',
  IFTTT: process.env.REACT_APP_IFTTT_URL ?? '',
} as const;
export const LINE = {
  CLIENT_ID: process.env.REACT_APP_LINE_CLIENT_ID ?? '',
} as const;