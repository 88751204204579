import { ENDPOINTS } from '@/utils/const/endpoints';
import { HTTPMethods } from '@/utils/const/HTTPMethods';
import { getService } from '@/utils/function';
import {
  ICreateSensorTopoLocationPayload,
  IDeleteSensorTopoLocationPayload,
  IUpdateSensorTopoLocationPayload,
} from '@/utils/type/IApiSensorLocation';

const fetchGetAllSensor = async () =>
  (
    await getService(
      {
        url: ENDPOINTS.getAllSensor(),
        method: HTTPMethods.GET,
      },
    )
  )();

const fetchCreateSensorTopoLocation = async (payload:　ICreateSensorTopoLocationPayload) =>
  (
    await getService(
      {
        data: payload.data,
        url: ENDPOINTS.createSensorTopoLocation(),
        method: HTTPMethods.POST,
      },
    )
  )();

const fetchUpdateSensorTopoLocation = async (payload:　IUpdateSensorTopoLocationPayload) =>
  (
    await getService(
      {
        data: payload.data,
        url: ENDPOINTS.updateSensorTopoLocation(),
        method: HTTPMethods.PUT,
      },
    )
  )();

const fetchDeleteSensorTopoLocation = async (payload:　IDeleteSensorTopoLocationPayload) =>
  (
    await getService(
      {
        data: payload.data,
        url: ENDPOINTS.deleteSensorTopoLocation(),
        method: HTTPMethods.POST,
      },
    )
  )();


export const sensorTopoLocationServices = {
  fetchGetAllSensor,
  fetchCreateSensorTopoLocation,
  fetchUpdateSensorTopoLocation,
  fetchDeleteSensorTopoLocation,
};
