import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import { topoBuildingActions } from "@/store/topoBuilding";
import { topoBuildingServices } from "@/store/topoBuilding/services";
import { getAxiosErrMsg } from "@/utils/function";
import { ITopoBuilding, RequestTopoBuildingPayloadType } from "@/utils/type/IApiTopoBuilding";

function* handleRequestTopoBuilding({
  payload,
}: PayloadAction<RequestTopoBuildingPayloadType>) {
  try {
    const data: ITopoBuilding[] = yield call(
      topoBuildingServices.fetchTopoBuilding,
      payload,
    );
    yield put(topoBuildingActions.success({ data }));
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(topoBuildingActions.failure(errMsg));
  }
}

export function* topoBuildingSaga() {
  yield takeLatest(topoBuildingActions.request.type, handleRequestTopoBuilding);
}