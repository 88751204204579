import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  IBuild,
  IBuildsState,
  RequestBuildsPayloadType,
} from '@/utils/type/IApiBuilds';

const initialState: IBuildsState = {
  isFetching: false,
  error: null,
  data: [],
};

const buildsSlice = createSlice({
  name: 'builds',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestBuildsPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(
      state,
      action: PayloadAction<{
        isFullReplacement: boolean;
        data: IBuild | IBuild[];
      }>,
    ) {
      const { payload } = action;

      // isFullReplacement:true means current index.data will be fully replaced by the response data
      // isFullReplacement:false means index.data will just be updated
      const originalData = payload.isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.data = getUpdatedData({
        originalData,
        incommingData: payload.data,
        uidName: 'PK',
      });
    },
  },
});

export const buildsActions = buildsSlice.actions;
export const buildsReducer = buildsSlice.reducer;
