import { ENDPOINTS } from '@/utils/const/endpoints';
import { HTTPMethods } from '@/utils/const/HTTPMethods';
import { getService } from '@/utils/function';
import {
  CreateBucketPayload,
  CreateTopoNoFilePayload,
  CreateTopoPayload,
  DeleteTopoPayload,
  UpdateTopoPayload,
} from "@/utils/type/IApiTopo";

const fetchGetTopoNestListWithoutUrn = async () =>
  (
    await getService(
      {
        url: ENDPOINTS.getTopoNestListWithoutUrn(),
        method: HTTPMethods.GET,
      },
    )
  )();

const fetchGetTopoNestList = async () =>
  (
    await getService(
      {
        url: ENDPOINTS.getTopoNestList(),
        method: HTTPMethods.GET,
      },
    )
  )();

const fetchCreateTopo = async (payload: CreateTopoPayload)=>
  (
    await getService(
      {
        url: ENDPOINTS.createTopo(),
        method: HTTPMethods.POST,
        data: payload.data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
  )();

const fetchCreateTopoNoFile = async (payload: CreateTopoNoFilePayload)=>
  (
    await getService(
      {
        url: ENDPOINTS.createTopoNoFile(),
        method: HTTPMethods.POST,
        data: payload.data,
      },
    )
  )();

const fetchUpdateTopo = async (payload: UpdateTopoPayload)=>
  (
    await getService(
      {
        url: ENDPOINTS.updateTopo(),
        method: HTTPMethods.PUT,
        data: payload.data,
      },
    )
  )();

const fetchDeleteTopo = async (payload: DeleteTopoPayload)=>
  (
    await getService(
      {
        url: ENDPOINTS.deleteTopo(),
        method: HTTPMethods.DELETE,
        data: payload.data,
      },
    )
  )();

const fetchCreateBucket = async (payload: CreateBucketPayload)=>
  (
    await getService(
      {
        url: ENDPOINTS.createBucket(),
        method: HTTPMethods.POST,
        data: payload.data,
      },
    )
  )();

export const topoServices = {
  fetchGetTopoNestList,
  fetchGetTopoNestListWithoutUrn,
  fetchCreateTopo,
  fetchCreateTopoNoFile,
  fetchUpdateTopo,
  fetchDeleteTopo,
  fetchCreateBucket,
};
