import { AxiosRequestConfig } from "axios";

import { ENDPOINTS } from "@/utils/const/endpoints";
import { getService } from "@/utils/function";
import { RequestReportSchedulePayloadType } from "@/utils/type/IApiReportSchedule";

const fetchReportSchedule = async (
  payload: RequestReportSchedulePayloadType,
) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };
  let propName = "res";

  switch (method) {
    case "GET":
      config.url = ENDPOINTS.reportSchedule();
      break;
    case "POST":
      config.url = ENDPOINTS.reportSchedule();
      config.data = payload.data;
      break;
    case "PUT":
      config.url = ENDPOINTS.reportSchedule(payload.endpointParams?.id);
      config.data = payload.data;
      break;
    case "DELETE":
      config.url = ENDPOINTS.reportSchedule(payload.endpointParams?.id);
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName))();
};

export const reportScheduleServices = {
  fetchReportSchedule,
};
