import { AxiosRequestConfig } from 'axios';

import { axiosAbriFmInstances } from '@/utils/const/axiosR';
import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestRepairFilePayloadType } from '@/utils/type/IApiRepairFile';

const fetchRepairFile = async (payload: RequestRepairFilePayloadType) => {
  const { method, endpointParams } = payload;

  let config: AxiosRequestConfig = {
    method,
  };
  let propName = 'res';
  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.repairFile(
        endpointParams.topoId,
        endpointParams.repairId,
      );
      break;
    case 'POST':
      config.url = ENDPOINTS.repairFile(
        endpointParams.topoId,
        endpointParams.repairId,
      );
      config.data = payload.data;
      break;
    case 'DELETE':
      config.url = ENDPOINTS.repairFile(
        endpointParams.topoId,
        endpointParams.repairId,
        endpointParams.status,
        endpointParams.fileName,
      );
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName, axiosAbriFmInstances.general))();
};

export const repairFileServices = { fetchRepairFile };
