import { AxiosRequestConfig } from 'axios';

import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestTenantPayloadType } from '@/utils/type/IApiTenant';

const fetchTenant = async (payload: RequestTenantPayloadType) => {
  const { method, endpointParams } = payload;

  let config: AxiosRequestConfig = { method };
  let propName = 'res';
  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.tenant(
        endpointParams.buildPK,
        'tenantId' in endpointParams ? endpointParams.tenantId : undefined,
      );
      break;
    case 'POST':
      config.url = ENDPOINTS.tenant(endpointParams.buildPK);
      config.data = payload.data;
      break;
    case 'PUT':
      config.url = ENDPOINTS.tenant(
        endpointParams.buildPK,
        endpointParams.tenantId,
      );
      config.data = payload.data;
      break;
    case 'DELETE':
      config.url = ENDPOINTS.tenant(
        endpointParams.buildPK,
        endpointParams.tenantId,
      );
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName))();
};

export const tenantServices = { fetchTenant };
