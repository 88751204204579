import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import PrimeReact from 'primereact/api';
import * as ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { SWRConfig } from "swr";

import { store } from '@/store';
import { DEFAULT_SWR_CONFIG } from "@/utils/const/DEFAULT_SWR_CONFIG";

// init
import '@/utils/init';

import theme from './utils/theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

// import 'primereact/resources/themes/light/theme.css';   // theme
// import 'primereact/resources/themes/dark/theme.css';
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';
import '@/utils/scss/index.scss';

PrimeReact.zIndex = {
  modal: 1100,    // dialog, sidebar
  overlay: 1401,  // dropdown, overlaypanel
  menu: 1000,     // overlay menus
  tooltip: 1100,   // tooltip
  toast: 1200,    // toast
};

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);
root.render(
  // <StrictMode>
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode}/><ChakraProvider theme={theme}>
      <ReduxProvider store={store}>
        <SWRConfig value={DEFAULT_SWR_CONFIG}>
          <App/>
        </SWRConfig>
      </ReduxProvider>
    </ChakraProvider>
  </>,
  // </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
