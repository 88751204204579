// OPEN is not real status code; status < 100 means it's OPEN(未結案)
export const RepairStatus = {
  PENDING: 0,
  ASSIGNED: 1,
  IN_PROGRESS: 2,
  PRE_COMPLETED: 90,
  COMPLETED: 100,
  CANCELLED: 101,
} as const;

export const RepairStatusName: { [key: string]: string } = {
  [RepairStatus.PENDING]: '待審核',
  [RepairStatus.ASSIGNED]: '已指派',
  [RepairStatus.IN_PROGRESS]: '處理中',
  [RepairStatus.PRE_COMPLETED]: '待驗收',
  [RepairStatus.COMPLETED]: '已完成',
  [RepairStatus.CANCELLED]: '已取消',
} as const;

export const RepairStatusOpenName: string = '未結案';
