import axios from 'axios';

import { API_URL } from './envRelative';

const axiosGeneral = axios.create({
  baseURL: API_URL.GENERAL,
});

const axiosRepair = axios.create({
  baseURL: API_URL.REPAIR,
});

const axiosEms = axios.create({
  baseURL: API_URL.EMS,
});

const axiosFsa = axios.create({
  baseURL: API_URL.FSA,
});

const axiosBts = axios.create({
  baseURL: API_URL.BTS,
});

export const axiosAbriFmInstances = {
  general: axiosGeneral,
  repair: axiosRepair,
  ems: axiosEms,
  fsa: axiosFsa,
  bts: axiosBts,
};
