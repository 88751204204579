import { AxiosRequestConfig } from 'axios';

import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestHouseholdPayloadType } from '@/utils/type/IApiHousehold';

const fetchHousehold = async (payload: RequestHouseholdPayloadType) => {
  const { method, endpointParams } = payload;

  let config: AxiosRequestConfig = { method };
  let propName = 'res';
  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.household(
        endpointParams.buildPK,
        'householdId' in endpointParams
          ? endpointParams.householdId
          : undefined,
      );
      break;
    case 'POST':
      config.url = ENDPOINTS.household(endpointParams.buildPK);
      config.data = payload.data;
      break;
    case 'PUT':
      config.url = ENDPOINTS.household(
        endpointParams.buildPK,
        endpointParams.householdId,
      );
      config.data = payload.data;
      break;
    case 'DELETE':
      config.url = ENDPOINTS.household(
        endpointParams.buildPK,
        endpointParams.householdId,
      );
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName))();
};

export const householdServices = { fetchHousehold };
