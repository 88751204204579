export const RoutePathNames = {
  LOGIN: '/login',
  ROOT_AUTHED: '/',
  ERROR_404: '404',
  LINE_TEACHING: 'line-teaching',
  DASHBOARD_ALARM: 'alarm',
  DASHBOARD_TEMP_HUMI: 'analyze',
  // 會員管理
  BUILDAUTH: 'build-auth',
  STAFF: 'staff',
  TENANT: 'tenant',
  // TOPOLOGY
  TOPOLOGY: 'topology',
  // BIM
  BIM: 'bim',
  // IftttApplet
  IFTTT_APPLET: 'applet',
  IFTTT_TEMP: 'temp',
  IFTTT_CREATE: 'create',
  IFTTT_EDITOR: 'editor',
  // Report
  REPORT: 'report',
  REPORT_SCHEDULE: 'report-schedule',
  //Connection Settings
  CONNECTION_SETTINGS: 'connection',
  // Front site pages
  FRONT_DASHBOARD_OVERALL: 'f-overall',
  FRONT_DASHBOARD_ALARM: 'f-alarm',
  FRONT_DASHBOARD_TEMP_HUMI: 'f-analyze',
  FRONT_DASHBOARD_ISSUE: 'f-issue',
  FRONT_REPAIR: 'repair',
  FRONT_REPAIR_CURD: ':repairMode',
} as const;
