import { ENDPOINTS } from '@/utils/const/endpoints';
import { HTTPMethods } from '@/utils/const/HTTPMethods';
import { getService } from '@/utils/function';
import {
  IGetBuildAuthParam,
  IPostBuildAuth,
} from '@/utils/type/IApiBuildsAuth';

const fetchBuildsAuth = async (payload: IGetBuildAuthParam) =>
  (
    await getService(
      {
        url: ENDPOINTS.buildAuth(payload.buildPK),
        method: HTTPMethods.GET,
      },
      'res',
    )
  )();

const updateBuildsAuth = async (payload: IPostBuildAuth) =>
  (
    await getService(
      {
        url: ENDPOINTS.buildAuth(payload.buildPK),
        method: HTTPMethods.POST,
        data: { users: payload.users },
      },
      'res',
    )
  )();

export const buildsAuthServices = { fetchBuildsAuth, updateBuildsAuth };
