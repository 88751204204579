import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// import { getUpdatedData } from '@/utils/function';
import {
  IExportCsvState,
  RequestExportCsvPayloadType,
} from '@/utils/type/IApiWarning';

const initialState: IExportCsvState = {
  isFetching: false,
  error: null,
  data: null,
};

const warningSlice = createSlice({
  name: 'warning',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestExportCsvPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(
      state,
      action: PayloadAction<{
          isFullReplacement: boolean;
          data: File;
        }>,
    ) {
      const { payload } = action;
      const csvData = new Blob([payload.data], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(csvData);

      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `警報資料.csv`;
      link.click();
      state.isFetching = false;
    },
  },
});

export const warningActions = warningSlice.actions;
export const warningReducer = warningSlice.reducer;
