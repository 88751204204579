import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  IUsers,
  IUsersState,
  RequestUsersPayloadType, UnbindLinePayload,
} from '@/utils/type/IApiUsers';

const initialState: IUsersState = {
  isFetching: false,
  error: null,
  successMethod: null,
  data: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestUsersPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(
      state,
      action: PayloadAction<{
        isFullReplacement: boolean;
        successMethod: string;
        data: IUsers | IUsers[];
      }>,
    ) {
      const {
        payload: { isFullReplacement, successMethod, data },
      } = action;

      // isFullReplacement:true means current index.data will be fully replaced by the response data
      // isFullReplacement:false means index.data will just be updated
      const originalData = isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.successMethod = successMethod;
      state.data = getUpdatedData({
        originalData,
        incommingData: data,
        uidName: 'id',
      });
    },
    resetSucessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
    },
    unbindLine(state, action: PayloadAction<UnbindLinePayload>) {
      state.isFetching = true;
      state.error = null;
    },
    successUnBindLine(state, action: PayloadAction<IUsers | IUsers[]>) {
      const { payload } = action;
      const originalData = state.data;
      state.isFetching = false;
      state.successMethod = 'UNBIND_OK';
      state.data = getUpdatedData({
        originalData,
        incommingData: payload,
        uidName: 'id',
      });
    },
  },
});

export const usersActions = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
