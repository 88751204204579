import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { authedNS, commonNS, defaultNS, resources } from '@/utils/i18n/i18n';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: [commonNS, authedNS],
    defaultNS,
    resources,
    lng: 'zh', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: ['zh', 'en'],

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .then(() => {});
