import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IReportDashboard,
  IReportDashboardState,
  RequestReportDashboardPayloadType,
} from "@/utils/type/IApiReportDashboard";

const initialState: IReportDashboardState = {
  isFetching: false,
  error: null,
  data: [],
};

const reportDashboardSlice = createSlice({
  name: "reportDashboard",
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestReportDashboardPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = null;
    },
    success(state, action: PayloadAction<{ data: IReportDashboard[] }>) {
      const {
        payload: { data },
      } = action;
      state.isFetching = false;
      state.data = data;
    },
  },
});

export const reportDashboardActions = reportDashboardSlice.actions;
export const reportDashboardReducer = reportDashboardSlice.reducer;
