import { AxiosRequestConfig } from 'axios';

import { axiosAbriFmInstances } from '@/utils/const/axiosR';
import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestFsaPayloadType } from '@/utils/type/IApiFsa';

const fetchFsa = async (payload: RequestFsaPayloadType) => {
  const { method, endpointParams } = payload;
  let config: AxiosRequestConfig = { method };
  const propName = 'res';
  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.fsa(
        endpointParams.buildPK,
        'fsaId' in endpointParams ? endpointParams.fsaId : undefined,
      );
      break;
    case 'PUT':
      config.url = ENDPOINTS.fsa(endpointParams.buildPK, endpointParams.fsaId);
      config.data = payload.data;
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName, axiosAbriFmInstances.fsa))();
};

export const fsaServices = { fetchFsa };
