import { AxiosRequestConfig } from 'axios';

import { axiosAbriFmInstances } from '@/utils/const/axiosR';
import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { IGetEmsBuildHistoryPayload } from '@/utils/type/IApiEmsBuildHistory';

const fetchEmsBuildHistory = async (payload: IGetEmsBuildHistoryPayload) => {
  let config: AxiosRequestConfig = {
    url: ENDPOINTS.emsBuildHistory(),
    params: payload.params,
  };

  return (await getService(config, 'res', axiosAbriFmInstances.ems))();
};

export const emsBuildHistoryServices = { fetchEmsBuildHistory };
