import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { emsBuildHistoryActions } from '@/store/emsBuildHistory';
import { emsBuildHistoryServices } from '@/store/emsBuildHistory/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  IEmsBuildHistory,
  IGetEmsBuildHistoryPayload,
} from '@/utils/type/IApiEmsBuildHistory';

function* handleRequestEmsBuildHistory({
  payload,
}: PayloadAction<IGetEmsBuildHistoryPayload>) {
  try {
    const data: IEmsBuildHistory = yield call(
      emsBuildHistoryServices.fetchEmsBuildHistory,
      payload,
    );
    yield put(emsBuildHistoryActions.success(data));
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(emsBuildHistoryActions.failure(errMsg));
  }
}

export function* emsBuildHistorySaga() {
  yield takeLatest(
    emsBuildHistoryActions.request.type,
    handleRequestEmsBuildHistory,
  );
}
