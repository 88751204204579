import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  IHousehold,
  IHouseholdErrorPayload,
  IHouseholdState,
  IHouseholdSuccessPayload,
  RequestHouseholdPayloadType,
} from '@/utils/type/IApiHousehold';
import { ILease } from '@/utils/type/IApiLease';

const initialState: IHouseholdState = {
  isFetching: false,
  error: null,
  requestPayload: null,
  errorMethod: null,
  successMethod: null,
  data: [],
};

const householdSlice = createSlice({
  name: 'household',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestHouseholdPayloadType>) {
      state.isFetching = true;
      state.error = null;
      state.requestPayload = action.payload;
    },
    failure(state, action: PayloadAction<IHouseholdErrorPayload>) {
      state.isFetching = false;
      state.error = action.payload.error;
      state.errorMethod = action.payload.errorMethod;
    },
    success(state, action: PayloadAction<IHouseholdSuccessPayload>) {
      const {
        payload: { isFullReplacement, successMethod, data },
      } = action;

      // isFullReplacement:true means current index.data will be fully replaced by the response data
      // isFullReplacement:false means index.data will just be updated
      const originalData = isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.successMethod = successMethod;
      state.data =
        successMethod === 'DELETE' || typeof data === 'number'
          ? originalData.filter(({ householdId }) => householdId !== data)
          : getUpdatedData({
            originalData,
            incommingData: data,
            uidName: 'householdId',
          });
    },
    updateLease(state, action: PayloadAction<ILease>) {
      const originalData = state.data;
      const incommingLease = action.payload;
      const targetHousehold = originalData.find(
        ({ householdId }) => householdId === incommingLease.householdId,
      );
      if (!targetHousehold) return console.warn('No household needs to update');
      if (
        targetHousehold.lease !== null &&
        +new Date(incommingLease.createdAt) -
          +new Date(targetHousehold.createdAt) <
          0
      )
        return console.warn('Not the last lease');

      const incommingData: IHousehold = {
        ...targetHousehold,
        lease: incommingLease,
      };
      state.data = getUpdatedData<IHousehold>({
        originalData,
        incommingData,
        uidName: 'householdId',
      });
    },
    resetSucessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
      state.errorMethod = null;
    },
  },
});

export const householdActions = householdSlice.actions;
export const householdReducer = householdSlice.reducer;
