import { AxiosRequestConfig } from "axios";

import { ENDPOINTS } from "@/utils/const/endpoints";
import { getService } from "@/utils/function";
import { RequestConnectionTypePayloadType } from "@/utils/type/IApiConnection";

const fetchConnectionType = async (payload: RequestConnectionTypePayloadType) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };

  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.connectionType();
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config))();
};

export const connectionTypeServices = {
  fetchConnectionType,
};
