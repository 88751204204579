import { AxiosRequestConfig } from 'axios';

import { axiosAbriFmInstances } from '@/utils/const/axiosR';
import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestRepairPayloadType } from '@/utils/type/IApiRepair';

const fetchRepair = async (payload: RequestRepairPayloadType) => {
  const { method, endpointParams } = payload;
  let config: AxiosRequestConfig = { method };
  const propName = 'res';
  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.repair(
        endpointParams.topoId,
        'repairId' in endpointParams ? endpointParams.repairId : undefined,
      );
      break;
    case 'POST':
      config.url = ENDPOINTS.repair(endpointParams.topoId);
      config.data = payload.data;
      break;
    case 'PUT':
      config.url = ENDPOINTS.repair(
        endpointParams.topoId,
        endpointParams.repairId,
      );
      config.data = payload.data;
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }
  return (await getService(config, propName, axiosAbriFmInstances.general))();
};

export const repairServices = { fetchRepair };
