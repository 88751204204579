import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { buildsActions } from '@/store/builds';
import { buildsServices } from '@/store/builds/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  IBuild,
  RequestBuildsPayloadType,
} from '@/utils/type/IApiBuilds';

function* handleRequestBuilds({
  payload,
}: PayloadAction<RequestBuildsPayloadType>) {
  try {
    const data: IBuild | IBuild[] = yield call(
      buildsServices.fetchBuilds,
      payload,
    );
    const { isFullReplacement } = payload;
    yield put(buildsActions.success({ isFullReplacement, data }));
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(buildsActions.failure(errMsg));
  }
}

export function* buildsSaga() {
  yield takeLatest(buildsActions.request.type, handleRequestBuilds);
}
