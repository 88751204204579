import { createSlice,PayloadAction } from "@reduxjs/toolkit";

import { getUpdatedData } from "@/utils/function";
import { IConnection, IConnectionState, RequestConnectionPayloadType } from "@/utils/type/IApiConnection";

const initialState: IConnectionState = {
  isFetching: false,
  error: null,
  successMethod: null,
  data: [],
};

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestConnectionPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(
      state,
      action: PayloadAction<{
        isFullReplacement: boolean;
        successMethod: string;
        data: IConnection[];
      }>,
    ) {
      const { payload: { isFullReplacement, successMethod, data } } = action;

      // autodesk forge use
      sessionStorage.setItem("connection", JSON.stringify(data));

      const originalData = isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.successMethod = successMethod;
      state.data = getUpdatedData({
        originalData,
        incommingData: data,
        uidName: 'id',
      });
    },
    resetSuccessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const connectionActions = connectionSlice.actions;
export const connectionReducer = connectionSlice.reducer;