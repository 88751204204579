import { AxiosRequestConfig } from 'axios';

import { axiosAbriFmInstances } from '@/utils/const/axiosR';
import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestEmsBuildPayloadType } from '@/utils/type/IApiEmsBuild';

const fetchEmsBuild = async (payload: RequestEmsBuildPayloadType) => {
  let config: AxiosRequestConfig = {
    url: ENDPOINTS.emsBuild(),
  };
  if (payload) {
    config.method = 'POST';
    config.url = ENDPOINTS.emsBuild(payload.endpointParams.buildPK);
    config.data = payload.data;
  }

  return (await getService(config, 'res', axiosAbriFmInstances.ems))();
};

export const emsBuildServices = { fetchEmsBuild };
