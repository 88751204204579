import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { emsBuildActions } from '@/store/emsBuild';
import { emsBuildServices } from '@/store/emsBuild/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  IEmsBuildRequestSuccedPayload,
  RequestEmsBuildPayloadType,
} from '@/utils/type/IApiEmsBuild';

function* handleRequestEmsBuild({
  payload,
}: PayloadAction<RequestEmsBuildPayloadType>) {
  try {
    const data: IEmsBuildRequestSuccedPayload['data'] = yield call(
      emsBuildServices.fetchEmsBuild,
      payload,
    );
    yield put(
      emsBuildActions.success({
        successMethod: Array.isArray(data) ? 'GET' : 'POST',
        data,
      }),
    );
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(emsBuildActions.failure(errMsg));
  }
}

export function* emsBuildSaga() {
  yield takeLatest(emsBuildActions.request.type, handleRequestEmsBuild);
}
