import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import { reportActions } from "@/store/report";
import { reportServices } from "@/store/report/service";
import { getAxiosErrMsg } from "@/utils/function";
import { IReport, RequestReportPayloadType } from "@/utils/type/IApiReport";

function* handleRequestReport({
  payload,
}: PayloadAction<RequestReportPayloadType>) {
  try {
    const data: IReport[] = yield call(
      reportServices.fetchReport,
      payload,
    );
    const { isFullReplacement, method } = payload;
    yield put(
      reportActions.success({
        isFullReplacement,
        successMethod: method,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(reportActions.failure(errMsg));
  }
}
export function* reportSaga() {
  yield takeLatest(reportActions.request.type, handleRequestReport);
}