import { AxiosRequestConfig } from 'axios';

import { ENDPOINTS } from '@/utils/const/endpoints';
import { HTTPMethods } from '@/utils/const/HTTPMethods';
import { getService } from '@/utils/function';
import { IPostLoginPayload } from '@/utils/type/IApiLogin';

const login = async (payload: IPostLoginPayload) => {
  const config: AxiosRequestConfig = {
    url: ENDPOINTS.login(),
    method: HTTPMethods.POST,
    data: payload,
  };

  return await getService(config, 'res')();
};

const checkLogin = async () => {
  const config: AxiosRequestConfig = {
    url: ENDPOINTS.me(),
    method: HTTPMethods.GET,
  };

  return await getService(config)();
};

// Waiting for the backend to implement this
const logout = async () => {
  const config: AxiosRequestConfig = {
    url: ENDPOINTS.logout(),
    method: HTTPMethods.POST,
  };

  return await getService(config)();
};

export const loginServices = {
  login,
  checkLogin,
  logout,
};
