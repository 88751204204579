import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  IRepairErrorPayload,
  IRepairState,
  IRepairSuccessPayload,
  RequestRepairPayloadType,
} from '@/utils/type/IApiRepair';

const initialState: IRepairState = {
  isFetching: false,
  error: null,
  data: null,
  requestPayload: null,
  errorMethod: null,
  successMethod: null,
  successPostId: null,
};

const repairSlice = createSlice({
  name: 'repair',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestRepairPayloadType>) {
      state.isFetching = true;
      state.error = null;
      state.requestPayload = action.payload;
    },
    failure(state, action: PayloadAction<IRepairErrorPayload>) {
      state.isFetching = false;
      state.error = action.payload.error;
      state.errorMethod = action.payload.errorMethod;
    },
    success(state, action: PayloadAction<IRepairSuccessPayload>) {
      const { data, successMethod } = action.payload;
      state.isFetching = false;
      state.successMethod = successMethod;
      if (successMethod === 'POST' && 'repairId' in data)
        state.successPostId = data.repairId;
      if (Array.isArray(data)) state.data = data;
      else
        state.data = getUpdatedData({
          originalData: state.data ?? [],
          incommingData: data,
          uidName: 'repairId',
        });
    },
    resetSucessMethod(state) {
      state.successMethod = null;
      state.successPostId = null;
      state.requestPayload = null;
    },
    resetError(state) {
      state.error = null;
      state.errorMethod = null;
      state.requestPayload = null;
    },
  },
});

export const repairActions = repairSlice.actions;
export const repairReducer = repairSlice.reducer;
