import { FLOW_NODE_TYPE } from '@/utils/const/ifttt';
import { RepairStatus } from "@/utils/const/RepairStatus";
import { RoutePathNames } from "@/utils/const/RoutePathNames";

export const zhAuthed = {
  title: '研創建築數據應用服務',
  pathGroupName: {
    dashboard: '戰情室',
    user: '使用者管理',
    topology: '資料組織管理',
    bim: '建築模型',
    ifttt: '邏輯引擎',
    report: '報告',
    exception: '異常事件處理',
    connection: '數據連接',
    others: '其他',
  },
  pathName: {
    [RoutePathNames.DASHBOARD_TEMP_HUMI]: '溫/濕度分析',
    [RoutePathNames.DASHBOARD_ALARM]: '警報紀錄',
    [RoutePathNames.BUILDAUTH]: '住宅權限',
    [RoutePathNames.STAFF]: '人員管理',
    [RoutePathNames.TENANT]: '租戶管理',
    [RoutePathNames.TOPOLOGY]: '拓樸管理',
    [RoutePathNames.BIM]: 'BIM應用',
    [RoutePathNames.IFTTT_APPLET]: '目前流程',
    [RoutePathNames.IFTTT_TEMP]: '範例流程',
    [RoutePathNames.IFTTT_CREATE]: '建立流程',
    [RoutePathNames.IFTTT_EDITOR]: '流程編輯器',
    [RoutePathNames.REPORT]: '報表',
    [RoutePathNames.REPORT_SCHEDULE]: '排程',
    [RoutePathNames.CONNECTION_SETTINGS]: '連線管理',
    [RoutePathNames.FRONT_DASHBOARD_OVERALL]: '總覽',
    [RoutePathNames.FRONT_DASHBOARD_TEMP_HUMI]: '溫/濕度分析',
    [RoutePathNames.FRONT_DASHBOARD_ALARM]: '警報紀錄',
    [RoutePathNames.FRONT_DASHBOARD_ISSUE]: '異常事件',
    [RoutePathNames.FRONT_REPAIR]: '報修管理',
  },
  header: {
    changeSiteToFront: '切換至前台',
    changeSiteToBack: '切換至後台',
  },
  topology: {
    addBucket: '新增Bucket',
  },
  dashboard: {
    noSensorInfo: '此拓樸節點與其所有子節點下無任何感測器',
    failedToFetchTopoTitle: '拓樸資料取得失敗',
    topoModelNodePlaceholder: '-- 選擇模型節點 --',
    topoFloorNodePlaceholder: '-- 選擇階層節點 --',
    alarm: '警報',
    alarmCreate: '發佈時間',
    alarmLength: '持續時間',
    event: '異常事件',
    eventLastUpdate: '最近更新',
    eventCreate: '申報時間',
    eventAutoApply: '自動通報',
    eventManualApply: '手動通報',
  },
  eventStatus: {
    [RepairStatus.PENDING]: '待審核',
    [RepairStatus.ASSIGNED]: '已指派',
    [RepairStatus.IN_PROGRESS]: '處理中',
    [RepairStatus.PRE_COMPLETED]: '待驗收',
    [RepairStatus.COMPLETED]: '已完成',
    [RepairStatus.CANCELLED]: '已取消',
    open: '未結案',
    fallback: '未定義',
  },
  userRole: {
    SUPER_ADMIN: '超級管理員',
    ADMIN: '管理人員',
    EDITOR: '編輯者',
    TENANT: '租戶',
    INVALID: '無效',
  },
  costThisMonth: '本月花費',
  workingApplets: '執行中Applets',
  totalTraffic: '流程總量',
  filter: {
    name: '名稱',
    traffic: '流量',
    trafficToday: '今日流量',
    createdAt: '建立時間',
    updatedAt: '更新時間',
    complexity: '複雜度',
    type: '類型',
    isHideActive: '隱藏未啟用',
  },
  tempTab: {
    all: '全種類',
    hot: '熱門',
    sensor: '感測器',
    actuator: '執行器',
    buildSystem: '建築系統',
    app: '應用程式',
    ai: 'AI辨識/預測',
    fire: '消防',
    poison: '有害氣體',
    communication: '通訊軟體',
    schedule: '日期排程',
    environment: '環境變化',
    maintain: '設備維護',
  },
  tempCount: {
    input: '輸入數',
    output: '輸出數',
    node: '節點數',
    applet: '引用Applets數',
    condition: '條件數',
  },
  appletPage: {
    active: '執勤中',
    inactive: '未啟用',
    activeNodes: '節點數',
  },
  createPage: {
    fromScratch: {
      title: '從頭開始',
      desc: '從選擇一觸發事件流程的資料來源開始，逐項建立事件觸發的條件判定，以及接下來的處理方式。',
      feat1: '打造完全原創的自動化流程',
      feat2: '適合熟悉本服務操作的使用者',
      searchLabel: '選擇一觸發事件並進入編輯頁面',
      searchPlaceholder: '輸入觸發事件名稱',
      streightTo: '直接進入編輯頁面',
    },
    fromTemplate: {
      title: '由範本製作',
      desc: '從現有的範本選取一個情境相近的例子，以此為基礎進行修改，快速建立自己的自動化流程。',
      feat1: '快速建立自動化流程',
      feat2: '適合所有使用者',
      searchLabel: '選擇一範本並進入編輯頁面',
      searchPlaceholder: '輸入範本名稱',
      streightTo: '瀏覽更多範本',
    },
    fromApplet: {
      title: '由Applet拷貝',
      desc: '由目前已架設的Applet中選取一個情境相近的例子拷貝並修改，以快速建立自己的自動化流程。',
      feat1: '快速建立自動化流程',
      feat2: '適合所有使用者',
      searchLabel: '選擇一Applet並進入編輯頁面',
      searchPlaceholder: '輸入Applet名稱',
      streightTo: '瀏覽更多Applet',
    },
  },
  editorPage: {
    defaultAppletName: '未命名流程',
    controls: {
      prevStep: '上一步',
      nextStep: '下一步',
      save: '儲存Applet',
      saveAs: '另存新Applet',
      createTemp: '建立範本',
      addConditionNode: '新增條件節點',
      addInputNode: '新增輸入節點',
      addOutputNode: '新增輸出節點',
      copyNode: '複製節點',
      configNode: '設定節點',
      deleteSelection: '刪除選取',
      showGrid: '顯示格線',
      zoomIn: '放大',
      zoomOut: '縮小',
    },
    nodeType: {
      [FLOW_NODE_TYPE.trigger]: '觸發事件',
      [FLOW_NODE_TYPE.condition]: '判斷事件',
      [FLOW_NODE_TYPE.input]: '輸入',
      [FLOW_NODE_TYPE.output]: '輸出',
    },
    nodeConfig: {
      label: '節點名稱',
      frequency: '觸發頻率',
      frequencyUnit: '觸發單位',
      eventId: '觸發事件',
      eventIdPlacehold: '請選擇一件觸發事件',
      eventTargetIds: '輸入目標',
      eventTargetIdsPlacehold: '選擇輸入目標',
      eventCondition: '觸發條件',
      eventConditionAddAnd: '新增條件',
      eventConditionAddOr: '新增「或是」',
      eventConditionAddPlaceholder: '請選擇一個條件',
      eventConditionAnd: '且',
      eventConditionOr: '或是',
      eventConditionLabel: '條件',
      eventConditionOperator: '運算子',
      eventConditionValue: '值',
      eventConditionUnit: '單位',
      serviceId: '輸出媒介',
      serviceIdPlacehold: '選擇一個輸出媒介',
      targetIds: '輸出目標',
      targetIdsPlacehold: '選擇輸出目標',
      message: '輸出訊息',
      messagePlacehold: '輸入輸出訊息',
      topoStructure: '拓樸結構',
      sourceId: '輸入來源',
      sourceIdPlacehold: '選擇輸入來源',
      sourcePositionId: '來源節點',
      sourcePositionIdPlacehold: '選擇來源節點',
      repairTitle: '報修主題',
      repairTitlePlaceholder: '請輸入報修主題',
    },
    message: {
      appletNotFound: '找不到Applet',
    },
  },
  config: {
    cancel: '取消',
    submit: '確認',
    yes: '是',
    no: '否',
  },
  time: {
    second: '秒',
    minute: '分鐘',
    hour: '小時',
    day: '天',
  },
} as const;
