import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { householdActions } from '@/store/household';
import { leaseServices } from '@/store/lease/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  ILeaseSuccessPayload,
  RequestLeasePayloadType,
} from '@/utils/type/IApiLease';

import { leaseActions } from './';

function* handleRequestLease({
  payload,
}: PayloadAction<RequestLeasePayloadType>) {
  const { isFullReplacement, method } = payload;

  try {
    const data: ILeaseSuccessPayload['data'] = yield call(
      leaseServices.fetchLease,
      payload,
    );
    yield put(
      leaseActions.success({
        isFullReplacement,
        successMethod: method,
        data,
      }),
    );
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(leaseActions.failure({ error: errMsg, errorMethod: method }));
  }
}
// only update single lease
function* handleUpdateHouseHoldState({
  payload,
}: PayloadAction<ILeaseSuccessPayload>) {
  const { data } = payload;
  if (!Array.isArray(data)) {
    yield put(householdActions.updateLease(data));
  }
}

export function* leaseSaga() {
  yield takeLatest(leaseActions.request.type, handleRequestLease);
  yield takeLatest(leaseActions.success.type, handleUpdateHouseHoldState);
}
