import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import {
  IPostChangePasswordPayload,
  IPutUpdateSelfPayload,
} from '@/utils/type/IApiUpdateSelfOrChangePassword';

const userChangePassword = async (payload: IPostChangePasswordPayload) =>
  (
    await getService({
      url: ENDPOINTS.changePassword(),
      method: 'POST',
      data: payload.data,
    })
  )();

const tenantUpdateSelf = async ({
  endpointParams,
  data,
}: IPutUpdateSelfPayload) =>
  (
    await getService({
      url: ENDPOINTS.tenantUpdateSelf(
        endpointParams.buildPK,
        endpointParams.tenantId,
      ),
      method: 'PUT',
      data: data,
    })
  )();

export const updateSelfOrChangePasswordServices = {
  userChangePassword,
  tenantUpdateSelf,
};
