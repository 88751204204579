import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { unitActions } from '@/store/unit';
import { unitServices } from '@/store/unit/services';
import { getAxiosErrMsg } from '@/utils/function';
import { IUnit, RequestUnitPayloadType } from '@/utils/type/IApiUnit';

function* handleRequestUnit({
  payload,
}: PayloadAction<RequestUnitPayloadType>) {
  try {
    const data: IUnit[] = yield call(unitServices.fetchUnit, payload);
    const { isFullReplacement, method } = payload;
    yield put(
      unitActions.success({
        isFullReplacement,
        successMethod: method,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(unitActions.failure(errMsg));
  }
}

export function* unitSaga() {
  yield takeLatest(unitActions.request.type, handleRequestUnit);
}
