import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import { reportScheduleActions } from "@/store/reportSchedule";
import { reportScheduleServices } from "@/store/reportSchedule/service";
import { getAxiosErrMsg } from "@/utils/function";
import {
  IReportSchedule,
  RequestReportSchedulePayloadType,
} from "@/utils/type/IApiReportSchedule";

function* handleRequestReportSchedule({
  payload,
}: PayloadAction<RequestReportSchedulePayloadType>) {
  try {
    const data: IReportSchedule[] = yield call(
      reportScheduleServices.fetchReportSchedule,
      payload,
    );
    const { isFullReplacement, method } = payload;
    yield put(
      reportScheduleActions.success({
        isFullReplacement,
        successMethod: method,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(reportScheduleActions.failure(errMsg));
  }
}
export function* reportScheduleSaga() {
  yield takeLatest(
    reportScheduleActions.request.type,
    handleRequestReportSchedule,
  );
}
