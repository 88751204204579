import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getUpdatedData } from "@/utils/function";
import {
  IReportSchedule,
  IReportScheduleState,
  RequestReportSchedulePayloadType,
} from "@/utils/type/IApiReportSchedule";

const initialState: IReportScheduleState = {
  isFetching: false,
  error: null,
  successMethod: null,
  data: [],
};

const reportScheduleSlice = createSlice({
  name: "reportSchedule",
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestReportSchedulePayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(
      state,
      action: PayloadAction<{
        isFullReplacement: boolean;
        successMethod: string;
        data: IReportSchedule[];
      }>,
    ) {
      const {
        payload: { isFullReplacement, successMethod, data },
      } = action;

      sessionStorage.setItem("reportSchedule", JSON.stringify(data));

      const originalData = isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.successMethod = successMethod;
      state.data = getUpdatedData({
        originalData,
        incommingData: data,
        uidName: "id",
      });
    },
    resetSuccessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const reportScheduleActions = reportScheduleSlice.actions;
export const reportScheduleReducer = reportScheduleSlice.reducer;
