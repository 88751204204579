import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { store } from '@/store';
import { loginActions } from '@/store/login';
import { axiosAbriFmInstances } from '@/utils/const/axiosR';
import { RoutePathNames } from "@/utils/const/RoutePathNames";
import { TenantStatus } from '@/utils/const/TenantStatus';
import { UserRole } from '@/utils/const/UserRole';
import { IGetDeepCopy, IGetUpdatedData, IObject, LeaseStatusType  } from '@/utils/type/common';
import { ILoginState, LoginDataType } from '@/utils/type/IApiLogin';
import { IUnit } from '@/utils/type/IApiUnit';

type GetServiceType = (
  config: AxiosRequestConfig,
  propName?: string | number,
  axiosInstance?: AxiosInstance,
) => () => any;
// Default axios instance: axiosAbriFmInstances.general
export const getService: GetServiceType =
  (config, propName, axiosInstance = axiosAbriFmInstances.general) =>
    async () => {
      let res;
      try {
        res = await axiosInstance(config);
      } catch (e) {
        throw e;
      }

      return propName ? res.data[propName] : res.data;
    };

export const checkLoginValid = (loginData: LoginDataType | null): boolean => {
  if (!loginData) return false;
  // I am a user(staff)
  if ('role' in loginData)
    return (
      loginData.active &&
      loginData.role >= UserRole.SUPER_ADMIN &&
      loginData.role <= UserRole.EDITOR
    );
  // I am a tenant
  return loginData.status === TenantStatus.ACTIVE;
};

export const isNullish = (x: any) => x === null || x === undefined;

/**
 * Only suitable for JSON allowed Data types
 * @param ref - Object or Array which can pass in JSON.stringify
 */
export const getDeepCopy: IGetDeepCopy = (ref) =>
  JSON.parse(JSON.stringify(ref));

// Based on GET /things or /things/{id}
export const getUpdatedData: IGetUpdatedData = ({
  originalData,
  incommingData,
  uidName,
}) => {
  const newData = Array.isArray(incommingData)
    ? incommingData
    : [incommingData];

  // Shallow copy is enough for index management
  const updatedData = [...originalData];

  newData.forEach((newObj) => {
    const targetIndex: number = originalData.findIndex(
      (oldObj) => oldObj[uidName] === newObj[uidName],
    );
    if (targetIndex !== -1) {
      updatedData[targetIndex] = newObj;
    } else updatedData.push(newObj);
  });

  return updatedData;
};

export const getNumberByString = (idStr: string | undefined): number =>
  idStr ? parseInt(idStr, 10) : NaN;

// Be sure all values of obj are different
export const getObjectKeyByValue = (obj: IObject, value: any) => {
  const target = Object.entries(obj).find(([, val]) => val === value);
  return target ? target[0] : null;
};

export const getUnitName = (units: IUnit[], unitId?: IUnit['id'] | null) =>
  unitId ? units.find((unit) => unit.id === unitId)?.name ?? '' : '無所屬';

export const isObject = (value: any) =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export const checkIsEqualArray = (...arrs: any[][]) => {
  if (arrs.length < 2) return true;

  let a = [...arrs[0]];
  a.sort();
  for (let i = 1; i < arrs.length; ++i) {
    let b = [...arrs[i]];
    if (a.length !== b.length) return false;

    b.sort();
    for (let j = 0; j < a.length; ++j) {
      if (a[j] !== b[j]) return false;
    }
  }

  return true;
};

export const checkIsIncludedArray = (targetArr: any[], ...arrs: any[][]) => {
  if (arrs.length === 0) return true;

  const allElements = arrs.reduce((prev, next) => prev.concat(next), []);
  const targetSet = new Set(targetArr);
  const beforeTargetSize = targetSet.size;
  const afterTargetSet = new Set([...targetSet.values(), ...allElements]);

  return beforeTargetSize === afterTargetSet.size;
};

export const getUnionArray = (...arrs: any[][]) => {
  const allElements = arrs.reduce((prev, next) => prev.concat(next), []);
  return [...new Set(allElements)];
};

export const getDivisionArray = (targetArr: any[], ...arrs: any[][]) => {
  if (arrs.length === 0) return targetArr;

  const allElements = arrs.reduce((prev, next) => prev.concat(next), []);
  const divisionArr = [...new Set(allElements)];
  const targetSet = new Set(targetArr);
  divisionArr.forEach((division) => targetSet.delete(division));

  return [...targetSet];
};

export const getFormatNumberStr = (num: number) =>
  new Intl.NumberFormat('en-US').format(num);

// if err is not AxiosError, return defalutErrMsg
export const getAxiosErrMsg = (
  err: unknown,
  defaultErrMsg: string = '錯誤',
): string => {
  if (!axios.isAxiosError(err)) return defaultErrMsg;
  // sometimes error response data is just string
  if (typeof err.response?.data === 'string') return err.response.data;
  // It's ideal type of error response data
  if (
    'message' in err.response?.data &&
    typeof err.response?.data.message === 'string'
  )
    return err.response.data.message;
  // sometimes no error response
  return err.message;
};

// Set token to all axios instances header and localStorage
export const setAxiosInstancesAuth = (token: string) => {
  Object.values(axiosAbriFmInstances).forEach(
    (instance) =>
      (instance.defaults.headers.common['Authorization'] = `${token}`),
  );
};

export const removeAxiosInstancesAuth = () => {
  Object.values(axiosAbriFmInstances).forEach(
    (instance) => delete instance.defaults.headers.common['Authorization'],
  );
};

export const setLocalStorageAuth = (token: string) => {
  localStorage.setItem('accessToken', token);
};

export const removeLocalStorageAuth = () => {
  localStorage.removeItem('accessToken');
};

const checkAccessTokenBeforeRequest = (
  config: AxiosRequestConfig,
  accessToken: string,
) => {
  const localAccessToken = localStorage.getItem('accessToken');
  // Normal case
  if (accessToken === localAccessToken) return config;

  // If token is not match,
  // it means token is changed by other tab or the end user clear the localStorage
  // So we need to update the token in all axios instance and try login again

  // Ignore if RESET is fired,
  // because you only need do this once if this function is called multiple times
  // happened when multiple requests are fired at the same time
  if (store.getState().login.isLoginChecked) {
    // login with localAccessToken by authedPage useEffect
    if (localAccessToken) store.dispatch(loginActions.loginAgain());
    else store.dispatch(loginActions.logout());
  }
  return Promise.reject(new Error('Token is not match'));
};

const checkStatusOnRejected = (err: any) => {
  if (!axios.isAxiosError(err)) return Promise.reject(err);

  const status = err.response?.status;
  if (status === 401 || status === 403) {
    // do this to log out if store not RESETed yet
    store.getState().login.isLoginChecked &&
      store.dispatch(
        loginActions.failure(getAxiosErrMsg(err, '登入已過期，請重新登入')),
      );
  }
  return Promise.reject(err);
};

// TODO: refactor onFullfilled and onRejected for new axios version
export const setAxiosInterceptors = (accessToken: string) => {
  Object.values(axiosAbriFmInstances).forEach((instance) => {
    // @ts-ignore
    instance.interceptors.request.use((config) =>
      checkAccessTokenBeforeRequest(config, accessToken),
    );
    instance.interceptors.response.use(
      (res) => res,
      (err) => checkStatusOnRejected(err),
    );
  });
};

export const clearAxiosInstancesInterceptors = () => {
  Object.values(axiosAbriFmInstances).forEach((instance) => {
    instance.interceptors.request.clear();
    instance.interceptors.response.clear();
  });
};

export const getUserRole = (loginData: ILoginState['data'] | null): number => {
  if (!loginData) return Infinity;
  // I am a user(staff)
  if ('role' in loginData) return loginData.role;
  // I am a tenant
  return 10000;
};

export const getLeaseStatusStr = (status: LeaseStatusType) => {
  switch (status) {
    case 'inactive':
      return '已停止';
    case 'outdated':
      return '已過期';
    case 'activeFuture':
      return '已啟用(未開始)';
    case 'active':
      return '已啟用';
    default:
      return 'N/A';
  }
};

export function isNotEmptyArray<T>(arr: T[]): arr is NotEmptyArray<T> {
  return arr.length !== 0;
}

// nevigate to correct entry page after change site
export const getEntryPage = (userRole: number, isBackSite: boolean) => {
  if(!isBackSite) return RoutePathNames.FRONT_DASHBOARD_OVERALL;

  return userRole === UserRole.SUPER_ADMIN || userRole === UserRole.ADMIN
    ? RoutePathNames.IFTTT_APPLET
    : userRole === UserRole.EDITOR
      ? RoutePathNames.BIM
      : RoutePathNames.LOGIN;
};