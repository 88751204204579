import { AxiosRequestConfig } from 'axios';

import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestUsersPayloadType, UnbindLinePayload } from '@/utils/type/IApiUsers';

const fetchUsers = async (payload: RequestUsersPayloadType) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };
  let propName = 'res';

  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.users(payload.endpointParams?.id);
      break;
    case 'POST':
      config.url = ENDPOINTS.users();
      config.data = payload.data;
      break;
    case 'PUT':
      config.url = ENDPOINTS.users(payload.endpointParams.id);
      config.data = payload.data;
      break;
    case 'DELETE':
      config.url = ENDPOINTS.users(payload.endpointParams.id);
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName))();
};

const unbindLine = async (payload: UnbindLinePayload) =>
  (
    await getService({
      url: ENDPOINTS.unbindLine(),
      method: 'POST',
      data: payload,
    }, 'res')
  )();

export const usersServices = {
  fetchUsers,
  unbindLine,
};
