import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import { sensorActions } from "@/store/sensor";
import { sensorServices } from "@/store/sensor/services";
import { getAxiosErrMsg } from "@/utils/function";
import { ISensor, RequestSensorPayloadType } from "@/utils/type/IApiSensor";

function* handleRequestSensor({
  payload,
}: PayloadAction<RequestSensorPayloadType>) {
  try {
    const data: ISensor[] = yield call(
      sensorServices.fetchSensor,
      payload,
    );
    yield put(sensorActions.success({ data }));
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(sensorActions.failure(errMsg));
  }
}

export function* sensorSaga() {
  yield takeLatest(sensorActions.request.type, handleRequestSensor);
}