import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  CreateBucketPayload,
  CreateTopoNoFilePayload,
  CreateTopoPayload, DeleteTopoPayload,
  ITopo,
  ITopoState,
  RequestTopoPayloadType,
  UpdateTopoPayload,
} from '@/utils/type/IApiTopo';

const initialState: ITopoState = {
  isFetching: false,
  operate: null,
  error: null,
  data: [],
};

const topoSlice = createSlice({
  name: 'topo',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestTopoPayloadType>) {
      state.isFetching = true;
      state.error = null;
      state.operate = null;
    },
    requestWithoutUrn(state, action: PayloadAction<RequestTopoPayloadType>) {
      state.isFetching = true;
      state.error = null;
      state.operate = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
      state.operate = null;
    },
    success(
      state,
      action: PayloadAction<{
        isFullReplacement: boolean;
        operate: string;
        data: ITopo | ITopo[];
      }>,
    ) {
      const { payload } = action;
      state.operate = payload.operate;
      // isFullReplacement:true means current index.data will be fully replaced by the response data
      // isFullReplacement:false means index.data will just be updated
      const originalData = payload.isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.data = getUpdatedData({
        originalData,
        incommingData: payload.data,
        uidName: 'node_id',
      });
    },
    createTopo(state, action: PayloadAction<CreateTopoPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    createTopoNoFile(state, action: PayloadAction<CreateTopoNoFilePayload>) {
      state.isFetching = true;
      state.error = null;
    },
    updateTopo(state, action: PayloadAction<UpdateTopoPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    deleteTopo(state, action: PayloadAction<DeleteTopoPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    createBucket(state, action: PayloadAction<CreateBucketPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    resetSuccess(state) {
      state.isFetching = false;
      state.operate = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const topoActions = topoSlice.actions;
export const topoReducer = topoSlice.reducer;
