import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  AppletData,
  AppletErrorPayload,
  AppletState,
  AppletSuccessPayload, AppletUpdatePayload,
  RequestAppletPayload,
} from "@/utils/type/ifttt";

const initialState: AppletState = {
  isFetching: false,
  error: null,
  requestPayload: null,
  errorMethod: null,
  successMethod: null,
  successMessage: null,
  operate: null,
  data: [],
};

const appletSlice = createSlice({
  name: 'applet',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestAppletPayload>) {
      state.isFetching = true;
      state.error = null;
      state.errorMethod = null;
      state.successMethod = null;
      state.successMessage = null;
      state.operate = null;
      state.requestPayload = action.payload;
    },
    failure(state, action: PayloadAction<AppletErrorPayload>) {
      state.isFetching = false;
      state.error = action.payload.error;
      state.errorMethod = action.payload.errorMethod;
    },
    success(state, action: PayloadAction<AppletSuccessPayload>) {
      const { payload } = action;
      const { data, successMethod, operate, isFullReplacement } = payload;

      state.isFetching = false;
      state.successMethod = successMethod;
      state.operate = operate;
      // if response is a success message
      if (typeof data === 'object' && 'message' in data) {
        state.successMessage = data.message;
        return;
      }

      // isFullReplacement:true means current index.data will be fully replaced by the response data
      // isFullReplacement:false means index.data will just be updated
      const originalData: AppletData[] = isFullReplacement ? [] : state.data;
      state.data =
        typeof data === 'number'
          ? originalData.filter(({ appletId }) => appletId !== data)
          : getUpdatedData({
            originalData,
            incommingData: data,
            uidName: 'appletId',
          });
    },
    resetSucess(state) {
      state.successMethod = null;
      state.successMessage = null;
      state.operate = null;
    },
    resetError(state) {
      state.error = null;
      state.errorMethod = null;
    },
    updateActive(state, action: PayloadAction<AppletUpdatePayload>) {
      state.isFetching = true;
      state.error = null;
    },
  },
});

export const appletActions = appletSlice.actions;
export const appletReducer = appletSlice.reducer;
