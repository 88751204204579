import {
  extendTheme,
  type ThemeConfig,
  withDefaultColorScheme,
} from '@chakra-ui/react';

import components from './components';
import foundations from './foundations';

const direction = 'ltr';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: 'chakra',
};

export const theme = {
  direction,
  ...foundations,
  components,
  config,
};

export default extendTheme(
  theme,
  withDefaultColorScheme({
    colorScheme: 'primary',
  }),
  withDefaultColorScheme({
    colorScheme: 'primary',
    components: ['Button'],
  }),
);
