import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { appletServices } from '@/store/applet/services';
import { getAxiosErrMsg } from '@/utils/function';
import { AppletSuccessPayload, AppletUpdatePayload, RequestAppletPayload } from "@/utils/type/ifttt";

import { appletActions } from './';

function* handleRequestApplet({
  payload,
}: PayloadAction<RequestAppletPayload>) {
  const { isFullReplacement, method, operate } = payload;
  let data: AppletSuccessPayload['data'];
  try {
    data = yield call(
      appletServices.fetchApplet,
      payload,
    );
    if(data === undefined) {
      throw new Error('找不到Applet');
    }
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(appletActions.failure({ error: errMsg, errorMethod: method }));
    return;
  }

  const successPayload: AppletSuccessPayload = { isFullReplacement, successMethod: method, operate, data };
  yield put( appletActions.success( successPayload ));
}

function* handleUpdateActive({
  payload,
}:PayloadAction<AppletUpdatePayload>){
  const { isFullReplacement, method, operate } = payload;

  try{
    const data: AppletSuccessPayload['data']= yield call(
      appletServices.fetchUpdateActive,
      payload,
    );
    const successPayload: AppletSuccessPayload = { isFullReplacement, successMethod: method, operate, data };

    yield put(
      appletActions.success(successPayload),
    );
  }catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(appletActions.failure({ error: errMsg, errorMethod: method }));
  }
}

export function* appletSaga() {
  yield takeLatest(appletActions.request.type, handleRequestApplet);
  yield takeLatest(appletActions.updateActive.type, handleUpdateActive);
}
