import { CrudMethod } from "@/utils/type/common";
import { IBuild } from '@/utils/type/IApiBuilds';
import { IConnection } from "@/utils/type/IApiConnection";
import { IFsa } from '@/utils/type/IApiFsa';
import { IHousehold } from '@/utils/type/IApiHousehold';
import { ILease } from '@/utils/type/IApiLease';
import { IRepair } from '@/utils/type/IApiRepair';
import { IRepairType } from '@/utils/type/IApiRepairType';
import { IReport } from "@/utils/type/IApiReport";
import { IReportSchedule } from "@/utils/type/IApiReportSchedule";
import { ITenant } from '@/utils/type/IApiTenant';
import { IUnit } from '@/utils/type/IApiUnit';

export const ENDPOINTS = {
  login: () => `/login`,
  logout: () => `/logout`,
  household: (buildPK: IBuild['PK'], householdId?: IHousehold['householdId']) =>
    `/${buildPK}/household${householdId ? '/' + householdId : ''}`,
  lease: (buildPK: IBuild['PK'], leaseId?: ILease['leaseId']) =>
    `/${buildPK}/lease${leaseId ? '/' + leaseId : ''}`,
  tenant: (buildPK: IBuild['PK'], tenantId?: ITenant['id']) =>
    `/${buildPK}/tenant${tenantId ? '/' + tenantId : ''}`,
  tenantUpdateSelf: (buildPK: IBuild['PK'], tenantId: ITenant['id']) =>
    `/${buildPK}/tenant/${tenantId}/updateSelf`,
  users: (id?: string) => `/users${id ? '/' + id : ''}`,
  me: () => `/users/me`,
  builds: (buildPK?: string) => `/builds${buildPK ? '/' + buildPK : ''}`,
  buildAuth: (buildPK: IBuild['PK']) => `/buildsAuth/${buildPK}`,
  unit: (unitId?: IUnit['id']) => `/unit${unitId ? '/' + unitId : ''}`,
  repairType: (repairTypeId?: IRepairType['id']) => `/repairType${repairTypeId ? '/' + repairTypeId : ''}`,
  changePassword: () => `/changePassword`,
  report: (reportId?: IReport['id']) => `/report${reportId? '/' + reportId : ''}`,
  reportDashboard: () => `/reportDashboard`,
  reportSchedule: (reportScheduleId?: IReportSchedule["id"]) =>
    `/reportSchedule${reportScheduleId ? "/" + reportScheduleId : ""}`,
  repair: (topoId: number, repairId?: IRepair['repairId']) =>
    `/${topoId}/repair${repairId ? '/' + repairId : ''}`,
  repairFile: (
    topoId: number,
    repairId: IRepair['repairId'],
    status?: string,
    fileName?: string,
  ) =>
    `/${topoId}/repairFile/${repairId}` +
    (!isNaN(parseInt(status + '', 10)) && fileName
      ? `/${status}/${fileName}`
      : ''),
  sensor: (connectionId: string, buildingId?: string) =>
    `/connection/${connectionId}/sensor` + 
    (buildingId
      ? '?buildingId=' + buildingId
      : ''),
  connection: (connectionId?: IConnection['id']) =>
    `/connection${connectionId? '/' + connectionId : ''}`,
  connectionGetNestList: () => `/connection/getNestList`,
  connectionType: () => `/connectionType`,
  emsBuild: (buildPK?: string) =>
    buildPK ? `/buildDetails/${buildPK}` : '/builds',
  emsBuildHistory: () => '/builds/history',
  fsa: (buildPK: IBuild['PK'], fsaId?: IFsa['fsaId']) =>
    `/${buildPK}/fireSafetyAlert${fsaId ? '/' + fsaId : ''}`,
  getTopoNestList: () => `/topo/nestList`,
  getTopoNestListWithoutUrn: () => `/topo/nestListWithoutUrn`,
  createTopo: () => `/topo/create`,
  updateTopo: () => `/topo/update`,
  deleteTopo: () => `/topo/delete`,
  createBucket: () => `/topo/createBucket`,
  exportWarningCsv: () => `/sensor_topo_location/exportWarningCsv`,
  unbindLine: () => `/line/unbind`,
  createTopoNoFile: () => `/topo/createNotFile`,
  getAllSensor: () => `/sensor_topo_location/getAllSensor`,
  createSensorTopoLocation: () => `/sensor_topo_location/create`,
  updateSensorTopoLocation: () => `/sensor_topo_location/update`,
  deleteSensorTopoLocation: () => `/sensor_topo_location/delete`,
};

export const ENDPOINT_IFTTT = {
  applet: (method: CrudMethod , appletId?: number) => {
    switch(method) {
      case 'read':
        return appletId ? `/applet_setting/single/${appletId}` : '/applet_setting/list';
      default:
        return `/applet_setting/${method}`;
    }
  },
  updateAppletActive:()=> `/applet_setting/updateActive`,
};