import { setLocale } from 'yup';

import { ErrorMessages } from '@/utils/const/ErrorMessages';

setLocale({
  mixed: {
    default: ErrorMessages.mixedDefault,
    required: ErrorMessages.mixedRequired,
  },
  string: {
    email: ErrorMessages.stringEmail,
  },
});
