import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { repairTypeActions } from '@/store/repairType';
import { repairTypeServices } from '@/store/repairType/services';
import { getAxiosErrMsg } from '@/utils/function';
import { IRepairType, RequestRepairTypePayloadType } from '@/utils/type/IApiRepairType';

function* handleRequestRepairType({
  payload,
}: PayloadAction<RequestRepairTypePayloadType>) {
  try {
    const data: IRepairType[] = yield call(repairTypeServices.fetchRepairType, payload);
    const { isFullReplacement, method } = payload;
    yield put(
      repairTypeActions.success({
        isFullReplacement,
        successMethod: method,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(repairTypeActions.failure(errMsg));
  }
}

export function* repairTypeSaga() {
  yield takeLatest(repairTypeActions.request.type, handleRequestRepairType);
}
