import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { repairFileActions } from '@/store/repairFile';
import { repairFileServices } from '@/store/repairFile/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  IRepairFileStatusGroup,
  IRepairFileSuccessPayload,
  RequestRepairFilePayloadType,
} from '@/utils/type/IApiRepairFile';

function* handleRequestRepairFile({
  payload,
}: PayloadAction<RequestRepairFilePayloadType>) {
  const { method } = payload;
  try {
    const data: IRepairFileStatusGroup = yield call(
      repairFileServices.fetchRepairFile,
      payload,
    );
    let successPayload: IRepairFileSuccessPayload = {
      successMethod: method,
      data: { [payload.endpointParams.repairId]: data },
    };

    yield put(repairFileActions.success(successPayload));
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(
      repairFileActions.failure({ error: errMsg, errorMethod: method }),
    );
  }
}

export function* repairFileSaga() {
  yield takeLatest(repairFileActions.request.type, handleRequestRepairFile);
}
