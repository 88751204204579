// en namespaces
import { enAuthed } from '@/utils/i18n/enAuthed';
import { enCommon } from '@/utils/i18n/enCommon';
// zh namespaces
import { zhAuthed } from '@/utils/i18n/zhAuthed';
import { zhCommon } from '@/utils/i18n/zhCommon';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const commonNS = 'common';
export const authedNS = 'authed';

export const defaultNS = authedNS;

export const resources = {
  zh: {
    [commonNS]: zhCommon,
    [authedNS]: zhAuthed,
  },
  en: {
    [commonNS]: enCommon,
    [authedNS]: enAuthed,
  },
} as const;
