import { AxiosRequestConfig } from 'axios';

import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestLeasePayloadType } from '@/utils/type/IApiLease';

const fetchLease = async (payload: RequestLeasePayloadType) => {
  const { method, endpointParams } = payload;

  let config: AxiosRequestConfig = { method };
  let propName = 'res';
  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.lease(
        endpointParams.buildPK,
        'leaseId' in endpointParams ? endpointParams.leaseId : undefined,
      );
      break;
    case 'POST':
      config.url = ENDPOINTS.lease(endpointParams.buildPK);
      config.data = payload.data;
      break;
    case 'PUT':
      config.url = ENDPOINTS.lease(
        endpointParams.buildPK,
        endpointParams.leaseId,
      );
      config.data = payload.data;
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName))();
};

export const leaseServices = { fetchLease };
