export const FLOW_NODE_TYPE = {
  trigger: 'flowNodeTrigger',
  condition: 'flowNodeCondition',
  input: 'flowNodeInput',
  output: 'flowNodeOutput',
} as const;

export const FLOW_CHART_CONTIANER_TYPE = {
  applet: 'applet',
  temp: 'temp',
  notAssigned: 'notAssigned',
} as const;

export const FLOW_NODE_CONDITION_OPERATOR = {
  equal: '==',
  notEqual: '!=',
  greaterThan: '>',
  greaterThanOrEqual: '>=',
  lessThen: '<',
  lessThenOrEqual: '<=',
} as const;

export const FLOW_NODE_DEFAULT_WIDTH = 300;
