import { Flex, FlexProps, Spinner } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const ChakraMotionFlex = motion(Flex);

interface LoadingOverlayProps extends FlexProps {
  isOpen?: boolean;
}
export const LoadingOverlay = ({
  isOpen = true,
  ...flexProps
}: LoadingOverlayProps) => {
  return isOpen ? (
    <ChakraMotionFlex
      display={'flex'}
      w={'full'}
      h={'full'}
      position={'absolute'}
      top={0}
      left={0}
      justify={'center'}
      align={'center'}
      zIndex={'overlay'}
      bg={'blackAlpha.500'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      // @ts-ignore no problem in operation, although type error appears.
      transition={{ duration: 0.3, ease: 'easeOut' }}
      {...flexProps}
    >
      <Spinner color={'primary.500'} />
    </ChakraMotionFlex>
  ) : null;
};
