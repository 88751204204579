import { AxiosRequestConfig } from 'axios';

import { ENDPOINT_IFTTT } from '@/utils/const/endpoints';
import { HTTPMethods } from "@/utils/const/HTTPMethods";
import { getService } from '@/utils/function';
import { AppletUpdatePayload, RequestAppletPayload } from "@/utils/type/ifttt";

const fetchApplet = async (payload: RequestAppletPayload) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };
  switch (method) {
    case 'GET':
      config.url = ENDPOINT_IFTTT.applet(
        'read',
        'endpointParams' in payload && 'appletId' in payload.endpointParams
          ? payload.endpointParams.appletId
          : undefined,
      );
      break;
    case 'POST':
      config.method = 'POST';
      config.url = ENDPOINT_IFTTT.applet('create');
      config.data = payload.data;
      break;
    case 'PUT':
      config.method = 'POST';
      config.url = ENDPOINT_IFTTT.applet('update');
      config.data = payload.data;
      break;
    case 'DELETE':
      config.method = 'POST';
      config.url = ENDPOINT_IFTTT.applet('delete');
      config.data = payload.data;
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config))();
};

const fetchUpdateActive = async (payload: AppletUpdatePayload)=>
  (
    await getService(
      {
        data: payload.data,
        url: ENDPOINT_IFTTT.updateAppletActive(),
        method: HTTPMethods.POST,
      },
    )
  )();

export const appletServices = { fetchApplet, fetchUpdateActive };
