import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  IEmsBuildRequestSuccedPayload,
  IEmsBuildState,
  RequestEmsBuildPayloadType,
} from '@/utils/type/IApiEmsBuild';

const initialState: IEmsBuildState = {
  isFetching: false,
  error: null,
  successMethod: null,
  data: null,
};

const emsBuildSlice = createSlice({
  name: 'emsBuild',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestEmsBuildPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(state, action: PayloadAction<IEmsBuildRequestSuccedPayload>) {
      const { data, successMethod } = action.payload;
      state.isFetching = false;
      state.successMethod = successMethod;
      if (Array.isArray(data)) state.data = data;
      // POST method
      else {
        state.data = getUpdatedData({
          originalData: state.data ?? [],
          incommingData: data,
          uidName: 'PK',
        });
      }
    },
    resetSucessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const emsBuildActions = emsBuildSlice.actions;
export const emsBuildReducer = emsBuildSlice.reducer;
