export const zhCommon = {
  title: '研創建築數據應用服務',
  account: '帳號',
  password: '密碼',
  login: {
    subTitle: '',
    rememberMe: '記住帳號',
    submit: '登入',
    success: '登入成功',
  },
  edit: '編輯',
  dateRangePanel: {
    start: '開始日期',
    end: '結束日期',
    day: '日',
    week: '週',
    month: '月',
    year: '年',
  },
} as const;
