import { AxiosRequestConfig } from "axios";

import { ENDPOINTS } from "@/utils/const/endpoints";
import { getService } from "@/utils/function";
import { RequestReportDashboardPayloadType } from "@/utils/type/IApiReportDashboard";

const fetchDashboard = async (payload: RequestReportDashboardPayloadType) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };

  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.reportDashboard();
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config))();
};

export const reportDashboardServices = {
  fetchDashboard,
};
