import { createSlice,PayloadAction } from "@reduxjs/toolkit";

import { IConnectionTypeState, RequestConnectionTypePayloadType } from "@/utils/type/IApiConnection";

const initialState: IConnectionTypeState = {
  isFetching: false,
  error: null,
  data: [],
};

const connectionTypeSlice = createSlice({
  name: 'connectionType',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestConnectionTypePayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = null;
    },
    success(state, action: PayloadAction<{ data: string[] }>) {
      const { payload: { data } } = action;
      state.isFetching = false;
      state.data = data;
    },
  },
});

export const connectionTypeActions = connectionTypeSlice.actions;
export const connectionTypeReducer = connectionTypeSlice.reducer;