import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  IEmsBuildHistory,
  IEmsBuildHistoryState,
  IGetEmsBuildHistoryPayload,
} from '@/utils/type/IApiEmsBuildHistory';

const initialState: IEmsBuildHistoryState = {
  isFetching: false,
  error: null,
  data: null,
};

const emsBuildHistorySlice = createSlice({
  name: 'emsBuildHistory',
  initialState,
  reducers: {
    request(state, action: PayloadAction<IGetEmsBuildHistoryPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(state, action: PayloadAction<IEmsBuildHistory>) {
      state.isFetching = false;
      state.data = action.payload;
    },
  },
});

export const emsBuildHistoryActions = emsBuildHistorySlice.actions;
export const emsBuildHistoryReducer = emsBuildHistorySlice.reducer;
