import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getAxiosErrMsg } from '@/utils/function';
import {
  IRepair,
  RequestRepairPayloadType,
} from '@/utils/type/IApiRepair';

import { repairActions } from './index';
import { repairServices } from './services';

function* handleRequestRepair({
  payload,
}: PayloadAction<RequestRepairPayloadType>) {
  const { isFullReplacement, method } = payload;

  try {
    const data: IRepair | IRepair[] = yield call(
      repairServices.fetchRepair,
      payload,
    );
    yield put(
      repairActions.success({ successMethod: method, isFullReplacement, data }),
    );
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(repairActions.failure({ error: errMsg, errorMethod: method }));
  }
}

export function* repairSaga() {
  yield takeLatest(repairActions.request.type, handleRequestRepair);
}
