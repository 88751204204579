import { RepairStatus } from "@/utils/const/RepairStatus";
import { RoutePathNames } from "@/utils/const/RoutePathNames";

export const enAuthed = {
  title: 'Architectural Data Application Services',
  pathGroupName: {
    dashboard: 'Dashboard',
    user: 'User',
    topology: 'Topology',
    bim: 'BIM Management',
    ifttt: 'Work Flow Automation',
    report: 'Report',
    exception: 'Exception Handling',
    connection: 'Connection Settings',
    others: 'Others',
  },
  pathName: {
    [RoutePathNames.DASHBOARD_TEMP_HUMI]: 'Overall',
    [RoutePathNames.DASHBOARD_ALARM]: 'Analysis',
    [RoutePathNames.BUILDAUTH]: 'Build Auth',
    [RoutePathNames.STAFF]: 'Staff',
    [RoutePathNames.TENANT]: 'Tenant',
    [RoutePathNames.TOPOLOGY]: 'Topology',
    [RoutePathNames.BIM]: 'BIM Management',
    [RoutePathNames.IFTTT_APPLET]: 'Applet',
    [RoutePathNames.IFTTT_TEMP]: 'Template',
    [RoutePathNames.IFTTT_CREATE]: 'Create',
    [RoutePathNames.IFTTT_EDITOR]: 'Editor',
    [RoutePathNames.REPORT]: 'Report',
    [RoutePathNames.REPORT_SCHEDULE]: 'Report Schedule',
    [RoutePathNames.CONNECTION_SETTINGS]: 'Connection Settings',
    [RoutePathNames.FRONT_DASHBOARD_OVERALL]: 'Overall',
    [RoutePathNames.FRONT_DASHBOARD_TEMP_HUMI]: 'Analysis',
    [RoutePathNames.FRONT_DASHBOARD_ALARM]: 'Alarm',
    [RoutePathNames.FRONT_DASHBOARD_ISSUE]: 'Issue',
    [RoutePathNames.FRONT_REPAIR]: 'Repair',
  },
  header: {
    changeSiteToFront: 'To Front Site',
    changeSiteToBack: 'To Back Site',
  },
  dashboard: {
    noSensorInfo: 'No sensor under this topology node',
    failedToFetchTopoTitle: 'Failed to fetch topology data',
    topoModelNodePlaceholder: '-- Select Model Node --',
    topoFloorNodePlaceholder: '-- Select Floor Node --',
    alarm: 'Alarms',
    alarmCreate: 'Alarm Create',
    alarmLength: 'Alarm Length',
    event: 'Events',
    eventLastUpdate: 'Last Update',
    eventCreate: 'Create Date',
    eventAutoApply: 'Auto Apply',
    eventManualApply: 'Manual Apply',
  },
  eventStatus: {
    [RepairStatus.PENDING]: 'pending',
    [RepairStatus.ASSIGNED]: 'assigned',
    [RepairStatus.IN_PROGRESS]: 'in progress',
    [RepairStatus.PRE_COMPLETED]: 'pre completed',
    [RepairStatus.COMPLETED]: 'completed',
    [RepairStatus.CANCELLED]: 'cancelled',
    open: 'open',
    fallback: 'N/A',
  },
} as const;
