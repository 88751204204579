import { AxiosRequestConfig } from "axios";

import { ENDPOINTS } from "@/utils/const/endpoints";
import { getService } from "@/utils/function";
import { RequestSensorPayloadType } from "@/utils/type/IApiSensor";

const fetchSensor = async (payload: RequestSensorPayloadType) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };

  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.sensor(
        payload.endpointParams.connectionId,
        payload.endpointParams.buildingId,
      );
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config))();
};

export const sensorServices = {
  fetchSensor,
};
