import { AxiosRequestConfig } from "axios";

import { ENDPOINTS } from "@/utils/const/endpoints";
import { getService } from "@/utils/function";
import { RequestReportPayloadType } from "@/utils/type/IApiReport";

const fetchReport = async (payload: RequestReportPayloadType) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };
  let propName = 'res';

  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.report();
      break;
    case 'POST':
      config.url = ENDPOINTS.report();
      config.data = payload.data;
      break;
    case 'PUT':
      config.url = ENDPOINTS.report(payload.endpointParams?.id);
      config.data = payload.data;
      break;
    case 'DELETE':
      config.url = ENDPOINTS.report(payload.endpointParams?.id);
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName))();
};

export const reportServices = {
  fetchReport,
};
