import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  ITenantState,
  RequestTenantPayloadType,
  TenantSuccessPayloadType,
} from '@/utils/type/IApiTenant';

const initialState: ITenantState = {
  isFetching: false,
  error: null,
  requestPayload: null,
  successMethod: null,
  data: [],
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestTenantPayloadType>) {
      state.isFetching = true;
      state.error = null;
      state.requestPayload = action.payload;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(state, action: PayloadAction<TenantSuccessPayloadType>) {
      const { payload } = action;
      state.successMethod = payload.successMethod;
      state.isFetching = false;

      if (payload.successMethod !== 'DELETE') {
        const originalData = payload.isFullReplacement ? [] : state.data;
        state.data = getUpdatedData({
          originalData,
          incommingData: payload.data,
          uidName: 'id',
        });
      } else {
        state.data = state.data.filter(
          ({ id }) => id !== payload.deletedTenantId,
        );
      }
    },
    resetSucessMethod(state) {
      state.successMethod = null;
      state.requestPayload = null;
    },
    resetError(state) {
      state.error = null;
      state.requestPayload = null;
    },
  },
});

export const tenantActions = tenantSlice.actions;
export const tenantReducer = tenantSlice.reducer;
