import { AxiosRequestConfig } from "axios";

import { ENDPOINTS } from "@/utils/const/endpoints";
import { getService } from "@/utils/function";
import { RequestTopoBuildingPayloadType } from "@/utils/type/IApiTopoBuilding";

const fetchTopoBuilding = async (payload: RequestTopoBuildingPayloadType) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };

  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.connectionGetNestList();
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config))();
};

export const topoBuildingServices = {
  fetchTopoBuilding,
};
