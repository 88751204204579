import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { updateSelfOrChangePasswordActions } from '@/store/updateSelfOrChangePassword/index';
import { updateSelfOrChangePasswordServices } from '@/store/updateSelfOrChangePassword/services';
import { getAxiosErrMsg } from '@/utils/function';
import { RequestUpdateSelfOrChangePasswordPayloadType } from '@/utils/type/IApiUpdateSelfOrChangePassword';

function* handleUpdateSelfOrChangePassword({
  payload,
}: PayloadAction<RequestUpdateSelfOrChangePasswordPayloadType>) {
  try {
    yield 'endpointParams' in payload
      ? call(updateSelfOrChangePasswordServices.tenantUpdateSelf, payload)
      : call(updateSelfOrChangePasswordServices.userChangePassword, payload);
    yield put(updateSelfOrChangePasswordActions.success());
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(updateSelfOrChangePasswordActions.failure(errMsg));
  }
}

export function* updateSelfOrChangePasswordSaga() {
  yield takeLatest(
    updateSelfOrChangePasswordActions.request.type,
    handleUpdateSelfOrChangePassword,
  );
}
