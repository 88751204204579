import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getAxiosErrMsg } from '@/utils/function';
import { IFsa, RequestFsaPayloadType } from '@/utils/type/IApiFsa';

import { fsaActions } from './index';
import { fsaServices } from './services';

function* handleRequestFsa({ payload }: PayloadAction<RequestFsaPayloadType>) {
  const { isFullReplacement, method } = payload;

  try {
    const data: IFsa | IFsa[] = yield call(fsaServices.fetchFsa, payload);
    yield put(
      fsaActions.success({ successMethod: method, isFullReplacement, data }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(fsaActions.failure({ error: errMsg, errorMethod: method }));
  }
}

export function* fsaSaga() {
  yield takeLatest(fsaActions.request.type, handleRequestFsa);
}
