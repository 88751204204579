import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { tenantActions } from '@/store/tenant';
import { tenantServices } from '@/store/tenant/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  ITenant,
  RequestTenantPayloadType,
  TenantSuccessPayloadType,
} from '@/utils/type/IApiTenant';

function* handleRequestTenant({
  payload,
}: PayloadAction<RequestTenantPayloadType>) {
  try {
    const data: ITenant[] = yield call(tenantServices.fetchTenant, payload);
    const { method } = payload;
    let successPayload: TenantSuccessPayloadType;
    if (method !== 'DELETE') {
      successPayload = {
        successMethod: method,
        isFullReplacement: payload.isFullReplacement,
        data,
      };
    } else {
      successPayload = {
        successMethod: method,
        deletedTenantId: payload.endpointParams.tenantId,
      };
    }
    yield put(tenantActions.success(successPayload));
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(tenantActions.failure(errMsg));
  }
}

export function* tenantSaga() {
  yield takeLatest(tenantActions.request.type, handleRequestTenant);
}
