import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  IBuildAuthErrorPayload,
  IBuildAuthState,
  IBuildAuthSuccessPayload,
  IGetBuildAuthParam,
  IPostBuildAuth,
} from '@/utils/type/IApiBuildsAuth';

const initialState: IBuildAuthState = {
  isFetching: false,
  error: null,
  data: null,
  errorMethod: null,
  successMethod: null,
};

const buildsAuthSlice = createSlice({
  name: 'buildAuth',
  initialState,
  reducers: {
    request(state, action: PayloadAction<IGetBuildAuthParam>) {
      state.isFetching = true;
      state.error = null;
    },
    success(state, { payload }: PayloadAction<IBuildAuthSuccessPayload>) {
      const { successMethod, ...data } = payload;
      state.isFetching = false;
      state.successMethod = successMethod;

      state.data = data;
    },
    failure(state, action: PayloadAction<IBuildAuthErrorPayload>) {
      state.isFetching = false;
      state.error = action.payload.error;
      state.errorMethod = action.payload.errorMethod;
    },
    updateBuildsAuth(state, action: PayloadAction<IPostBuildAuth>) {
      state.isFetching = true;
      state.error = null;
    },
    resetSucessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
      state.errorMethod = null;
    },
  },
});

export const buildAuthActions = buildsAuthSlice.actions;
export const buildAuthReducer = buildsAuthSlice.reducer;
