export const enCommon = {
  title: 'Architectural Data Application Services',
  account: 'account',
  password: 'password',
  login: {
    subTitle: '',
    rememberMe: 'remember me',
    submit: 'log in',
    success: 'log in successfully',
  },
  edit: 'edit',
  dateRangePanel:{
    start: 'start date',
    end: 'end date',
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
  },
} as const;
