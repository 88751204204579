import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { RoutePathNames } from '@/utils/const/RoutePathNames';

const AuthedPage = lazy(() => import('../../components/Layout/AuthedPage'));
const Login = lazy(() => import('../../pages/Login'));
const Error404 = lazy(() => import('../../pages/Error404'));
const LineTeaching = lazy(() => import('../../pages/LineTeaching'));

const RootNavigator = lazy(() => import('../../pages/RootNavigator'));
const DashboardAlarm = lazy(() => import('../../pages/DashboardAlarm'));
const DashboardAnalysis = lazy(() => import('../../pages/DashboardAnalysis'));
const BuildAuth = lazy(() => import('../../pages/BuildAuth'));
const Staff = lazy(() => import('../../pages/Staff'));
const Tenant = lazy(() => import('../../pages/Tenant'));
const Topology = lazy(() => import('../../pages/Topology'));
const BIM = lazy(() => import('../../pages/DashboardOverall'));
const IftttApplet = lazy(() => import('../../pages/IftttApplet'));
const IftttTemp = lazy(() => import('../../pages/IftttTemp'));
const IftttCreate = lazy(() => import('../../pages/IftttCreate'));
const IftttEditor = lazy(() => import('../../pages/IftttEditor'));
const Report = lazy(() => import('../../pages/Report'));
const ReportSchedule = lazy(() => import('../../pages/ReportSchedule'));
const DashboardRepair = lazy(() => import('../../pages/DashboardRepair'));
const Repair = lazy(() => import('../../pages/Repair'));
const RepairCRUD = lazy(() => import('../../pages/RepairCRUD'));
const Connection = lazy(() => import('../../pages/Connection'));

export const routes: RouteObject[] = [
  {
    path: RoutePathNames.LOGIN,
    element: <Login />,
  },
  {
    path: RoutePathNames.ROOT_AUTHED,
    element: <AuthedPage />,
    children: [
      {
        index: true,
        element: <RootNavigator />,
      },
      {
        path: RoutePathNames.DASHBOARD_ALARM,
        element: <DashboardAlarm theme={'dark'}/>,
      },
      {
        path: RoutePathNames.DASHBOARD_TEMP_HUMI,
        element: <DashboardAnalysis theme={'dark'}/>,
      },
      {
        path: RoutePathNames.BUILDAUTH,
        element: <BuildAuth />,
      },
      {
        path: RoutePathNames.STAFF,
        element: <Staff />,
      },
      {
        path: RoutePathNames.TENANT,
        element: <Tenant />,
      },
      // TOPOLOGY
      {
        path: RoutePathNames.TOPOLOGY,
        element: <Topology />,
      },
      // BIM
      {
        path: RoutePathNames.BIM,
        element: <BIM />,
      },
      // IFTTT
      {
        path: RoutePathNames.IFTTT_APPLET,
        element: <IftttApplet />,
      },
      {
        path: RoutePathNames.IFTTT_TEMP,
        element: <IftttTemp />,
      },
      {
        path: RoutePathNames.IFTTT_CREATE,
        element: <IftttCreate />,
      },
      {
        path: RoutePathNames.IFTTT_EDITOR,
        element: <IftttEditor />,
      },
      {
        path: RoutePathNames.REPORT,
        element: <Report />,
      },
      {
        path: RoutePathNames.REPORT_SCHEDULE,
        element: <ReportSchedule />,
      },
      {
        path: RoutePathNames.CONNECTION_SETTINGS,
        element: <Connection />,
      },
      // Front Site below
      {
        path: RoutePathNames.FRONT_DASHBOARD_ALARM,
        element: <DashboardAlarm theme={'light'} />,
      },
      {
        path: RoutePathNames.FRONT_DASHBOARD_TEMP_HUMI,
        element: <DashboardAnalysis theme={'light'}  />,
      },
      {
        path: RoutePathNames.FRONT_DASHBOARD_OVERALL,
        element: <BIM />,
      },
      {
        path: RoutePathNames.FRONT_REPAIR,
        children: [
          { id: RoutePathNames.FRONT_REPAIR, index: true, element: <Repair /> },
          {
            id: RoutePathNames.FRONT_REPAIR_CURD,
            path: RoutePathNames.FRONT_REPAIR_CURD,
            element: <RepairCRUD />,
          },
        ],
      },
      {
        path: RoutePathNames.FRONT_DASHBOARD_ISSUE,
        element: <DashboardRepair />,
      },
      // 404 Page below
      {
        path: RoutePathNames.ERROR_404,
        element: <Error404 />,
      },
      {
        path: '*',
        element: <Navigate to={RoutePathNames.ERROR_404} replace />,
      },
    ],
  },
  {
    path: RoutePathNames.LINE_TEACHING,
    element: <LineTeaching />,
  },
];
