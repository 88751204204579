import { AxiosRequestConfig } from "axios";

import { ENDPOINTS } from "@/utils/const/endpoints";
import { getService } from "@/utils/function";
import { RequestConnectionPayloadType } from "@/utils/type/IApiConnection";

const fetchConnection = async (payload: RequestConnectionPayloadType) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };

  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.connection();
      break;
    case 'POST':
      config.url = ENDPOINTS.connection();
      config.data = payload.data;
      break;
    case 'PUT':
      config.url = ENDPOINTS.connection(payload.endpointParams?.id);
      config.data = payload.data;
      break;
    case 'DELETE':
      config.url = ENDPOINTS.connection(payload.endpointParams?.id);
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config))();
};

export const connectionServices = {
  fetchConnection,
};
