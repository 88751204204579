import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { householdServices } from '@/store/household/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  IHouseholdSuccessPayload,
  RequestHouseholdPayloadType,
} from '@/utils/type/IApiHousehold';

import { householdActions } from './';

function* handleRequestHousehold({
  payload,
}: PayloadAction<RequestHouseholdPayloadType>) {
  const { isFullReplacement, method } = payload;
  let data: IHouseholdSuccessPayload['data'];
  try {
    data = yield call(householdServices.fetchHousehold, payload);
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(householdActions.failure({ error: errMsg, errorMethod: method }));
    return;
  }
  yield put(
    householdActions.success({
      isFullReplacement,
      successMethod: method,
      data: method === 'DELETE' ? payload.endpointParams.householdId : data,
    }),
  );
}

export function* householdSaga() {
  yield takeLatest(householdActions.request.type, handleRequestHousehold);
}
