import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { warningActions } from "@/store/warning/index";
import { warningServices } from "@/store/warning/services";
import {
  RequestExportCsvPayloadType,
} from '@/utils/type/IApiWarning';

function* handleExportCsv({
  payload,
}:PayloadAction<RequestExportCsvPayloadType>) {
  try {
    const data: File = yield call(
      warningServices.fetchExportCsv,
      payload,
    );
    const { isFullReplacement } = payload;
    yield put(
      warningActions.success({
        isFullReplacement,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = "資料錯誤";
    yield put(warningActions.failure(errMsg));
  }
}

export function* warningSaga(){
  yield takeLatest(warningActions.request.type, handleExportCsv);
}