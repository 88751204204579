import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';

import { loginActions } from '@/store/login';
import { UserRole } from '@/utils/const/UserRole';
import {
  clearAxiosInstancesInterceptors,
  getAxiosErrMsg,
  removeAxiosInstancesAuth,
  removeLocalStorageAuth,
  setAxiosInstancesAuth,
  setAxiosInterceptors,
  setLocalStorageAuth,
} from '@/utils/function';
import {
  ILoginUser,
  IPostLoginPayload,
  IPostLoginUserResData,
} from '@/utils/type/IApiLogin';

import { loginServices } from './services';

// kick out user if not Super Admin, Admin and Editor
function* judgeLoginData(data: IPostLoginUserResData) {
  yield 'role' in data && data.role <= UserRole.EDITOR
    ? put(loginActions.success(data))
    : put(loginActions.failure('權限不足，不予登入。'));
}

function* handleLogout() {
  // remove all stored accessTokens
  removeAxiosInstancesAuth();
  removeLocalStorageAuth();
  // clear axios interceptors
  clearAxiosInstancesInterceptors();
  // reset store for security
  yield put({ type: 'RESET' });
  // navigate to login pages by AuthedPage useEffect
}

function* handleLoginAgain() {
  // remove axiosInstances auth but keep localStorage one
  removeAxiosInstancesAuth();
  // clear axios interceptors, or it will be called before login and fail
  clearAxiosInstancesInterceptors();
  // reset store for security
  yield put({ type: 'RESET_EXCEPT_IS_LOGIN_AGAIN' });
  // then AuthedPage useEffect will handle login event
}

function* handleLogin({ payload }: PayloadAction<IPostLoginPayload>) {
  // Remove all delayed state like res data or errors usually issued by async actions like redux saga
  yield put({ type: 'RESET_EXCEPT_LOGIN' });

  let data: IPostLoginUserResData;

  try {
    data = yield call(loginServices.login, payload);
  } catch (e) {
    const errMsg = getAxiosErrMsg(e, '登入錯誤');
    yield put(loginActions.failure(errMsg));
    return;
  }

  yield judgeLoginData(data);
}

function* handleCheckLogin({ payload }: PayloadAction<string>) {
  setAxiosInstancesAuth(payload);

  let data: ILoginUser;

  try {
    data = yield call(loginServices.checkLogin);
  } catch (e) {
    console.error(e);
    const errMsg = getAxiosErrMsg(e, '登入錯誤');
    yield put(loginActions.failure(errMsg));
    return;
  }

  yield judgeLoginData({ ...data, accessToken: payload });
}

function* handleLoginSuccess({
  payload,
}: PayloadAction<IPostLoginUserResData>) {
  // Remove all delayed state like res data or errors usually issued by async actions like redux saga
  yield put({ type: 'RESET_EXCEPT_LOGIN' });
  const { accessToken } = payload;
  setAxiosInstancesAuth(accessToken);
  setLocalStorageAuth(accessToken);
  setAxiosInterceptors(accessToken);
}

export function* loginSaga() {
  yield takeLatest(loginActions.request.type, handleLogin);
  yield takeLeading(loginActions.logout.type, handleLogout);
  yield takeLatest(loginActions.checkLogin.type, handleCheckLogin);
  yield takeLatest(loginActions.success.type, handleLoginSuccess);
  yield takeLeading(loginActions.loginAgain.type, handleLoginAgain);
}
