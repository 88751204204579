import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { LoadingOverlay } from './components/Overlay/LoadingOverlay';
import { routes } from './utils/const/routes';

const router = createBrowserRouter(routes);

export default function App() {
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
