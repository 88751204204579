import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  IUnit,
  IUnitState,
  RequestUnitPayloadType,
} from '@/utils/type/IApiUnit';

const initialState: IUnitState = {
  isFetching: false,
  error: null,
  successMethod: null,
  data: [],
};

const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestUnitPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(
      state,
      action: PayloadAction<{
        isFullReplacement: boolean;
        successMethod: string;
        data: IUnit | IUnit[];
      }>,
    ) {
      const {
        payload: { isFullReplacement, successMethod, data },
      } = action;

      // isFullReplacement:true means current index.data will be fully replaced by the response data
      // isFullReplacement:false means index.data will just be updated
      const originalData = isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.successMethod = successMethod;
      state.data = getUpdatedData({
        originalData,
        incommingData: data,
        uidName: 'id',
      });
    },
    resetSucessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const unitActions = unitSlice.actions;
export const unitReducer = unitSlice.reducer;
