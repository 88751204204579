import { all } from 'redux-saga/effects';

import { appletSaga } from "@/store/applet/saga";
import { buildAuthSaga } from '@/store/buildAuth/saga';
import { buildsSaga } from '@/store/builds/saga';
import { connectionSaga } from '@/store/connection/saga';
import { connectionTypeSaga } from '@/store/connectionType/saga';
import { emsBuildSaga } from '@/store/emsBuild/saga';
import { emsBuildHistorySaga } from '@/store/emsBuildHistory/saga';
import { fsaSaga } from '@/store/fsa/saga';
import { householdSaga } from '@/store/household/saga';
import { leaseSaga } from '@/store/lease/saga';
import { loginSaga } from '@/store/login/saga';
import { repairSaga } from '@/store/repair/saga';
import { repairFileSaga } from '@/store/repairFile/saga';
import { repairTypeSaga } from '@/store/repairType/saga';
import { reportSaga } from '@/store/report/saga';
import { reportDashboardSaga } from '@/store/reportDashboard/saga';
import { reportScheduleSaga } from '@/store/reportSchedule/saga';
import { sensorSaga } from '@/store/sensor/saga';
import { sensorTopoLocationSage } from '@/store/sensorTopoLocation/saga';
import { tenantSaga } from '@/store/tenant/saga';
import { topoSaga } from "@/store/topo/saga";
import { topoBuildingSaga } from '@/store/topoBuilding/saga';
import { unitSaga } from '@/store/unit/saga';
import { updateSelfOrChangePasswordSaga } from '@/store/updateSelfOrChangePassword/saga';
import { usersSaga } from '@/store/users/saga';
import { warningSaga } from '@/store/warning/saga';


export function* rootSaga() {
  yield all([
    loginSaga(),
    buildAuthSaga(),
    buildsSaga(),
    unitSaga(),
    updateSelfOrChangePasswordSaga(),
    usersSaga(),
    householdSaga(),
    leaseSaga(),
    tenantSaga(),
    repairSaga(),
    repairFileSaga(),
    repairTypeSaga(),
    reportSaga(),
    reportDashboardSaga(),
    reportScheduleSaga(),
    connectionSaga(),
    connectionTypeSaga(),
    topoBuildingSaga(),
    sensorSaga(),
    emsBuildSaga(),
    emsBuildHistorySaga(),
    fsaSaga(),
    topoSaga(),
    warningSaga(),
    appletSaga(),
    sensorTopoLocationSage(),
  ]);
}
