import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  IFsaErrorPayload,
  IFsaState,
  IFsaSuccessPayload,
  RequestFsaPayloadType,
} from '@/utils/type/IApiFsa';

const initialState: IFsaState = {
  isFetching: false,
  error: null,
  data: null,
  requestPayload: null,
  errorMethod: null,
  successMethod: null,
  successPostId: null,
};

const fsaSlice = createSlice({
  name: 'fsa',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestFsaPayloadType>) {
      state.isFetching = true;
      state.error = null;
      state.requestPayload = action.payload;
    },
    failure(state, action: PayloadAction<IFsaErrorPayload>) {
      state.isFetching = false;
      state.error = action.payload.error;
      state.errorMethod = action.payload.errorMethod;
    },
    success(state, action: PayloadAction<IFsaSuccessPayload>) {
      const { data, successMethod } = action.payload;
      state.isFetching = false;
      state.successMethod = successMethod;
      if (Array.isArray(data)) state.data = data;
      else
        state.data = getUpdatedData({
          originalData: state.data ?? [],
          incommingData: data,
          uidName: 'fsaId',
        });
    },
    resetSucessMethod(state) {
      state.successMethod = null;
      state.successPostId = null;
      state.requestPayload = null;
    },
    resetError(state) {
      state.error = null;
      state.errorMethod = null;
      state.requestPayload = null;
    },
  },
});

export const fsaActions = fsaSlice.actions;
export const fsaReducer = fsaSlice.reducer;
