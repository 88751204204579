export const UserRole = {
  SUPER_ADMIN: 0,
  ADMIN: 1,
  EDITOR: 2,
} as const;

export const UserRoleName: { [key: number]: string } = {
  [UserRole.SUPER_ADMIN]: '超級管理員',
  [UserRole.ADMIN]: '管理人員',
  [UserRole.EDITOR]: '編輯者',
} as const;

export const UserRoleColorScheme: { [key: number]: string } = {
  [UserRole.SUPER_ADMIN]: 'purple',
  [UserRole.ADMIN]: 'blue',
  [UserRole.EDITOR]: 'orange',
} as const;

export const InvalidUserRoleName = '無效';
export const InvalidUserColorScheme = 'gray';

export const TenantName = '租戶';
export const TenantColorScheme = 'green';
