import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  IRepairType,
  IRepairTypeState,
  RequestRepairTypePayloadType,
} from '@/utils/type/IApiRepairType';

const initialState: IRepairTypeState = {
  isFetching: false,
  error: null,
  successMethod: null,
  data: [],
};

const repairTypeSlice = createSlice({
  name: 'repairType',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestRepairTypePayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(
      state,
      action: PayloadAction<{
        isFullReplacement: boolean;
        successMethod: string;
        data: IRepairType | IRepairType[];
      }>,
    ) {
      const {
        payload: { isFullReplacement, successMethod, data },
      } = action;

      // isFullReplacement:true means current index.data will be fully replaced by the response data
      // isFullReplacement:false means index.data will just be updated
      const originalData = isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.successMethod = successMethod;
      state.data = getUpdatedData({
        originalData,
        incommingData: data,
        uidName: 'id',
      });
    },
    resetSuccessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const repairTypeActions = repairTypeSlice.actions;
export const repairTypeReducer = repairTypeSlice.reducer;
