import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ITopoBuilding, ITopoBuildingState, RequestTopoBuildingPayloadType } from "@/utils/type/IApiTopoBuilding";

const initialState: ITopoBuildingState = {
  isFetching: false,
  error: null,
  data: [],
};

const topoBuildingSlice = createSlice({
  name: 'topoBuilding',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestTopoBuildingPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = null;
    },
    success(state, action: PayloadAction<{ data: ITopoBuilding[] }>) {
      const { payload: { data } } = action;
      state.isFetching = false;
      state.data = data;
    },
  },
});

export const topoBuildingActions = topoBuildingSlice.actions;
export const topoBuildingReducer = topoBuildingSlice.reducer;