import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { topoActions } from '@/store/topo';
import { topoServices } from '@/store/topo/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  CreateBucketPayload,
  CreateTopoNoFilePayload,
  CreateTopoPayload, DeleteTopoPayload,
  ITopo,
  RequestTopoPayloadType, UpdateTopoPayload,
} from '@/utils/type/IApiTopo';

function* handleRequestGetTopoNestList({
  payload,
}: PayloadAction<RequestTopoPayloadType>) {
  try {
    const data: ITopo = yield call(
      topoServices.fetchGetTopoNestList,
    );
    const { isFullReplacement, operate } = payload;
    yield put(
      topoActions.success({ 
        isFullReplacement,
        operate,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(topoActions.failure(errMsg));
  }
}

function* handleRequestGetTopoNestListWithoutUrn({
  payload,
}: PayloadAction<RequestTopoPayloadType>) {
  try {
    const data: ITopo = yield call(
      topoServices.fetchGetTopoNestListWithoutUrn,
    );
    const { isFullReplacement, operate } = payload;
    yield put(
      topoActions.success({ 
        isFullReplacement,
        operate,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(topoActions.failure(errMsg));
  }
}

function* handleCreateTopo({
  payload,
}: PayloadAction<CreateTopoPayload>) {
  try {
    const data: ITopo = yield call(
      topoServices.fetchCreateTopo,
      payload,
    );
    const { isFullReplacement, operate } = payload;

    yield put(topoActions.success({
      isFullReplacement,
      operate,
      data,
    }));
  }catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(topoActions.failure(errMsg));
  }
}

function* handleCreateTopoNoFile({
  payload,
}: PayloadAction<CreateTopoNoFilePayload>) {
  try {
    const data: ITopo = yield call(
      topoServices.fetchCreateTopoNoFile,
      payload,
    );
    const { isFullReplacement, operate } = payload;

    yield put(topoActions.success({
      isFullReplacement,
      operate,
      data,
    }));
  }catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(topoActions.failure(errMsg));
  }
}

function* handleUpdateTopo({
  payload,
}: PayloadAction<UpdateTopoPayload>) {
  try {
    const data: ITopo = yield call(
      topoServices.fetchUpdateTopo,
      payload,
    );
    const { isFullReplacement, operate } = payload;

    yield put(topoActions.success({
      isFullReplacement,
      operate,
      data,
    }));
  }catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(topoActions.failure(errMsg));
  }
}

function* handleDeleteTopo({
  payload,
}: PayloadAction<DeleteTopoPayload>) {
  try {
    const data: ITopo = yield call(
      topoServices.fetchDeleteTopo,
      payload,
    );
    const { isFullReplacement, operate } = payload;

    yield put(topoActions.success({
      isFullReplacement,
      operate,
      data,
    }));
  }catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(topoActions.failure(errMsg));
  }
}

function* handleCreateBucket({
  payload,
}: PayloadAction<CreateBucketPayload>) {
  try {
    const data: ITopo = yield call(
      topoServices.fetchCreateBucket,
      payload,
    );
    const { isFullReplacement, operate } = payload;

    yield put(topoActions.success({
      isFullReplacement,
      operate,
      data,
    }));
  }catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(topoActions.failure(errMsg));
  }
}

export function* topoSaga() {
  yield takeLatest(topoActions.request.type, handleRequestGetTopoNestList);
  yield takeLatest(topoActions.requestWithoutUrn.type, handleRequestGetTopoNestListWithoutUrn);
  yield takeLatest(topoActions.createTopo.type, handleCreateTopo);
  yield takeLatest(topoActions.createTopoNoFile.type, handleCreateTopoNoFile);
  yield takeLatest(topoActions.updateTopo.type, handleUpdateTopo);
  yield takeLatest(topoActions.deleteTopo.type, handleDeleteTopo);
  yield takeLatest(topoActions.createBucket.type, handleCreateBucket);
}
