import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  ILoginState,
  IPostLoginPayload,
  LoginResDataType,
} from '@/utils/type/IApiLogin';

export const loginInitialState: ILoginState = {
  isFetching: false,
  isLoginChecked: false,
  isLoginAgain: false,
  error: null,
  data: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState: loginInitialState,
  reducers: {
    request(state, _action: PayloadAction<IPostLoginPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    success(state, action: PayloadAction<LoginResDataType>) {
      const { accessToken: _unused, ...rest } = action.payload;
      state.isFetching = false;
      state.isLoginChecked = true;
      state.data = rest;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.isLoginChecked = true;
      state.error = action.payload;
    },
    checkLogin(state, _action: PayloadAction<string>) {
      state.isFetching = true;
      state.error = null;
    },
    localUpdateLineActive(state, action: PayloadAction<boolean>) {
      if (state.data === null) return state;
      state.data = { ...state.data, lineActive: action.payload };
    },
    logout() {},
    // triggered by axios interceptors
    loginAgain(state) {
      state.isLoginAgain = true;
    },
    resetLoginAgain(state) {
      state.isLoginAgain = false;
    },
  },
});

export const loginActions = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
