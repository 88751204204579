import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import { reportDashboardActions } from "@/store/reportDashboard";
import { reportDashboardServices } from "@/store/reportDashboard/services";
import { getAxiosErrMsg } from "@/utils/function";
import { IReportDashboard, RequestReportDashboardPayloadType } from "@/utils/type/IApiReportDashboard";

function* handleRequestDashboard({
  payload,
}: PayloadAction<RequestReportDashboardPayloadType>) {
  try {
    const data: IReportDashboard[] = yield call(
      reportDashboardServices.fetchDashboard,
      payload,
    );
    yield put(reportDashboardActions.success({ data }));
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(reportDashboardActions.failure(errMsg));
  }
}

export function* reportDashboardSaga() {
  yield takeLatest(reportDashboardActions.request.type, handleRequestDashboard);
}
