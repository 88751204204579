import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";

import { connectionTypeActions } from "@/store/connectionType";
import { connectionTypeServices } from "@/store/connectionType/services";
import { getAxiosErrMsg } from "@/utils/function";
import { RequestConnectionTypePayloadType } from "@/utils/type/IApiConnection";

function* handleRequestConnectionType({
  payload,
}: PayloadAction<RequestConnectionTypePayloadType>) {
  try {
    const data: string[] = yield call(
      connectionTypeServices.fetchConnectionType,
      payload,
    );
    yield put(connectionTypeActions.success({ data }));
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(connectionTypeActions.failure(errMsg));
  }
}

export function* connectionTypeSaga() {
  yield takeLatest(connectionTypeActions.request.type, handleRequestConnectionType);
}