import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  IUpdateSelfOrChangePassword,
  RequestUpdateSelfOrChangePasswordPayloadType,
} from '@/utils/type/IApiUpdateSelfOrChangePassword';

const initialState: IUpdateSelfOrChangePassword = {
  isFetching: false,
  error: null,
  success: false,
};

const updateSelfOrChangePasswordSlice = createSlice({
  name: 'updateSelfOrChangePassword',
  initialState,
  reducers: {
    request(
      state,
      action: PayloadAction<RequestUpdateSelfOrChangePasswordPayloadType>,
    ) {
      state.isFetching = true;
      state.error = null;
      state.success = false;
    },
    success(state) {
      state.isFetching = false;
      state.success = true;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const updateSelfOrChangePasswordActions =
  updateSelfOrChangePasswordSlice.actions;
export const updateSelfOrChangePasswordReducer =
  updateSelfOrChangePasswordSlice.reducer;
