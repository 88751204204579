import { ENDPOINTS } from '@/utils/const/endpoints';
import { HTTPMethods } from '@/utils/const/HTTPMethods';
import { getService } from '@/utils/function';
import {
  RequestExportCsvPayloadType,
} from '@/utils/type/IApiWarning';

const fetchExportCsv = async (payload:　RequestExportCsvPayloadType) =>
  (
    await getService(
      {
        data: payload.data,
        url: ENDPOINTS.exportWarningCsv(),
        method: HTTPMethods.POST,
        responseType: 'blob',
      },
    )
  )();


export const warningServices = { fetchExportCsv };
