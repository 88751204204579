import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  IRepairFileErrorPayload,
  IRepairFileState,
  IRepairFileSuccessPayload,
  RequestRepairFilePayloadType,
} from '@/utils/type/IApiRepairFile';

const initialState: IRepairFileState = {
  isFetching: false,
  error: null,
  requestPayload: null,
  errorMethod: null,
  successMethod: null,
  data: {},
};

const repairFileSlice = createSlice({
  name: 'repairFile',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestRepairFilePayloadType>) {
      state.isFetching = true;
      state.error = null;
      state.requestPayload = action.payload;
    },
    failure(state, action: PayloadAction<IRepairFileErrorPayload>) {
      state.isFetching = false;
      state.error = action.payload.error;
      state.errorMethod = action.payload.errorMethod;
    },
    success(state, action: PayloadAction<IRepairFileSuccessPayload>) {
      const { payload } = action;
      state.successMethod = payload.successMethod;
      state.isFetching = false;
      state.data = payload.data;
    },
    resetSucessMethod(state) {
      state.successMethod = null;
      state.requestPayload = null;
    },
    resetError(state) {
      state.error = null;
      state.errorMethod = null;
      state.requestPayload = null;
    },
  },
});

export const repairFileActions = repairFileSlice.actions;
export const repairFileReducer = repairFileSlice.reducer;
