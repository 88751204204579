import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { sensorTopoLocationActions } from "@/store/sensorTopoLocation/index";
import { sensorTopoLocationServices } from "@/store/sensorTopoLocation/services";
import { getAxiosErrMsg } from "@/utils/function";
import {
  ICreateSensorTopoLocationPayload, IDeleteSensorTopoLocationPayload, IGetAllSensorPayload,
  IUpdateSensorTopoLocationPayload,
} from '@/utils/type/IApiSensorLocation';
import { SensorMapping } from "@/utils/type/Sensor";

function* handleGetAllSensor({
  payload,
}: PayloadAction<IGetAllSensorPayload>) {
  try {
    const data: SensorMapping[] = yield call(
      sensorTopoLocationServices.fetchGetAllSensor,
    );
    const { isFullReplacement } = payload;

    yield put(
      sensorTopoLocationActions.success({
        isFullReplacement,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(sensorTopoLocationActions.failure(errMsg));
  }
}

function* handleCreateSensorTopoLocation({
  payload,
}:PayloadAction<ICreateSensorTopoLocationPayload>) {
  try {
    const data: SensorMapping[] = yield call(
      sensorTopoLocationServices.fetchCreateSensorTopoLocation,
      payload,
    );
    const { isFullReplacement } = payload;
    yield put(
      sensorTopoLocationActions.success({
        isFullReplacement,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(sensorTopoLocationActions.failure(errMsg));  }
}

function* handleUpdateSensorTopoLocation({
  payload,
}:PayloadAction<IUpdateSensorTopoLocationPayload>) {
  try {
    const data: SensorMapping[] = yield call(
      sensorTopoLocationServices.fetchUpdateSensorTopoLocation,
      payload,
    );
    const { isFullReplacement } = payload;
    yield put(
      sensorTopoLocationActions.success({
        isFullReplacement,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(sensorTopoLocationActions.failure(errMsg));  }
}

function* handleDeleteSensorTopoLocation({
  payload,
}:PayloadAction<IDeleteSensorTopoLocationPayload>) {
  try {
    const data: SensorMapping[] = yield call(
      sensorTopoLocationServices.fetchDeleteSensorTopoLocation,
      payload,
    );
    const { isFullReplacement } = payload;
    yield put(
      sensorTopoLocationActions.success({
        isFullReplacement,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(sensorTopoLocationActions.failure(errMsg));  }
}

export function* sensorTopoLocationSage(){
  yield takeLatest(sensorTopoLocationActions.get.type, handleGetAllSensor);
  yield takeLatest(sensorTopoLocationActions.create.type, handleCreateSensorTopoLocation);
  yield takeLatest(sensorTopoLocationActions.update.type, handleUpdateSensorTopoLocation);
  yield takeLatest(sensorTopoLocationActions.delete.type, handleDeleteSensorTopoLocation);
}