import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from "@/utils/function";
import {
  ICreateSensorTopoLocationPayload, IDeleteSensorTopoLocationPayload,
  ISensorTopoLocationState, IUpdateSensorTopoLocationPayload } from '@/utils/type/IApiSensorLocation';
import { SensorMapping } from "@/utils/type/Sensor";

const initialState: ISensorTopoLocationState = {
  isFetching: false,
  error: null,
  data: [],
};

const sensorTopoLocationSlice = createSlice({
  name: 'sensorTopoLocation',
  initialState,
  reducers: {
    get(state) {
      state.isFetching = true;
      state.error = null;
    },
    create(state, action: PayloadAction<ICreateSensorTopoLocationPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    update(state, action: PayloadAction<IUpdateSensorTopoLocationPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    delete(state, action: PayloadAction<IDeleteSensorTopoLocationPayload>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    success(
      state,
      action: PayloadAction<{
        isFullReplacement: boolean;
        data: SensorMapping[];
      }>,
    ) {
      const { payload } = action;
      const originalData = payload.isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.data = getUpdatedData({
        originalData,
        incommingData: payload.data,
        uidName: 'node_id',
      });
    },
  },
});

export const sensorTopoLocationActions = sensorTopoLocationSlice.actions;
export const sensorTopoLocationReducer =sensorTopoLocationSlice.reducer;
