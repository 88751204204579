import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISensor, ISensorState, RequestSensorPayloadType } from "@/utils/type/IApiSensor";

const initialState: ISensorState = {
  isFetching: false,
  error: null,
  data: [],
};

const sensorSlice = createSlice({
  name: 'sensor',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestSensorPayloadType>) {
      state.isFetching = true;
      state.error = null;
    },
    failure(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = null;
    },
    success(state, action: PayloadAction<{ data: ISensor[] }>) {
      const { payload: { data } } = action;
      state.isFetching = false;
      state.data = data;
    },
  },
});

export const sensorActions = sensorSlice.actions;
export const sensorReducer = sensorSlice.reducer;