import { AxiosRequestConfig } from 'axios';

import { ENDPOINTS } from '@/utils/const/endpoints';
import { getService } from '@/utils/function';
import { RequestUnitPayloadType } from '@/utils/type/IApiUnit';

const fetchUnit = async (payload: RequestUnitPayloadType) => {
  const { method } = payload;

  let config: AxiosRequestConfig = { method };
  let propName = 'res';
  switch (method) {
    case 'GET':
      config.url = ENDPOINTS.unit();
      break;
    case 'POST':
      config.url = ENDPOINTS.unit();
      config.data = payload.data;
      break;
    case 'PUT':
      config.url = ENDPOINTS.unit(payload.endpointParams.id);
      config.data = payload.data;
      break;
    case 'DELETE':
      config.url = ENDPOINTS.unit(payload.endpointParams.id);
      break;
    default:
      throw new Error(`Method ${method} not supported for this endpoint`);
  }

  return (await getService(config, propName))();
};

export const unitServices = { fetchUnit };
