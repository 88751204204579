import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { buildAuthActions } from '@/store/buildAuth';
import { buildsAuthServices } from '@/store/buildAuth/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  IBuildAuth,
  IBuildAuthSuccessPayload,
  IGetBuildAuthParam,
  IPostBuildAuth,
} from '@/utils/type/IApiBuildsAuth';

function* handleGetBuildsAuth({ payload }: PayloadAction<IGetBuildAuthParam>) {
  try {
    const buildAuths: IBuildAuth[] = yield call(
      buildsAuthServices.fetchBuildsAuth,
      payload,
    );
    const data: IBuildAuthSuccessPayload = {
      buildPK: payload.buildPK,
      buildAuths,
      successMethod: 'GET',
    };

    yield put(buildAuthActions.success(data));
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(buildAuthActions.failure({ error: errMsg, errorMethod: 'GET' }));
  }
}

function* handleUpdateBuildsAuth({ payload }: PayloadAction<IPostBuildAuth>) {
  try {
    const buildAuths: IBuildAuth[] = yield call(
      buildsAuthServices.updateBuildsAuth,
      payload,
    );
    const data: IBuildAuthSuccessPayload = {
      buildPK: payload.buildPK,
      buildAuths,
      successMethod: 'POST',
    };

    yield put(buildAuthActions.success(data));
  } catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(buildAuthActions.failure({ error: errMsg, errorMethod: 'POST' }));
  }
}

export function* buildAuthSaga() {
  yield takeLatest(buildAuthActions.request.type, handleGetBuildsAuth);
  yield takeLatest(
    buildAuthActions.updateBuildsAuth.type,
    handleUpdateBuildsAuth,
  );
}
