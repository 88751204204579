import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { usersActions } from '@/store/users';
import { usersServices } from '@/store/users/services';
import { getAxiosErrMsg } from '@/utils/function';
import {
  IUsers,
  RequestUsersPayloadType,
  UnbindLinePayload,
} from '@/utils/type/IApiUsers';

function* handleRequestUsers({
  payload,
}: PayloadAction<RequestUsersPayloadType>) {
  try {
    const data: IUsers | IUsers[] = yield call(
      usersServices.fetchUsers,
      payload,
    );
    const { isFullReplacement, method } = payload;
    yield put(
      usersActions.success({
        isFullReplacement,
        successMethod: method,
        data,
      }),
    );
  } catch (e: unknown) {
    const errMsg = getAxiosErrMsg(e);
    yield put(usersActions.failure(errMsg));
  }
}

function* handleUnbindLine({
  payload,
}:PayloadAction<UnbindLinePayload>) {
  try {
    const data: IUsers | IUsers[] = yield call(
      usersServices.unbindLine,
      payload,
    );
    yield put(
      usersActions.successUnBindLine(data),
    );
  }catch (e) {
    const errMsg = getAxiosErrMsg(e);
    yield put(usersActions.failure(errMsg));
  }
}

export function* usersSaga() {
  yield takeLatest(usersActions.request.type, handleRequestUsers);
  yield takeLatest(usersActions.unbindLine.type, handleUnbindLine);
}
