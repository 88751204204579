import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getUpdatedData } from '@/utils/function';
import {
  ILeaseErrorPayload,
  ILeaseState,
  ILeaseSuccessPayload,
  RequestLeasePayloadType,
} from '@/utils/type/IApiLease';

const initialState: ILeaseState = {
  isFetching: false,
  error: null,
  requestPayload: null,
  errorMethod: null,
  successMethod: null,
  data: [],
};

const leaseSlice = createSlice({
  name: 'lease',
  initialState,
  reducers: {
    request(state, action: PayloadAction<RequestLeasePayloadType>) {
      state.isFetching = true;
      state.error = null;
      state.requestPayload = action.payload;
    },
    failure(state, action: PayloadAction<ILeaseErrorPayload>) {
      state.isFetching = false;
      state.error = action.payload.error;
      state.errorMethod = action.payload.errorMethod;
    },
    success(state, action: PayloadAction<ILeaseSuccessPayload>) {
      const {
        payload: { isFullReplacement, successMethod, data },
      } = action;

      // isFullReplacement:true means current index.data will be fully replaced by the response data
      // isFullReplacement:false means index.data will just be updated
      const originalData = isFullReplacement ? [] : state.data;
      state.isFetching = false;
      state.successMethod = successMethod;
      state.data = getUpdatedData({
        originalData,
        incommingData: data,
        uidName: 'leaseId',
      });
    },
    resetSucessMethod(state) {
      state.successMethod = null;
    },
    resetError(state) {
      state.error = null;
      state.errorMethod = null;
    },
  },
});

export const leaseActions = leaseSlice.actions;
export const leaseReducer = leaseSlice.reducer;
